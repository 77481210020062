/* UserMenu.css */
.user-menu-container {
  position: relative;
  z-index: 1000;
}

.user-avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: linear-gradient(135deg, #bd193b 0%, #ff385c 100%);
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 10px rgba(189, 25, 59, 0.2);
}

.user-avatar:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(189, 25, 59, 0.3);
  border-color: #ffffff;
}

.user-dropdown {
  position: absolute;
  top: calc(100% + 12px);
  right: 0;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1),
    0 1px 8px rgba(0, 0, 0, 0.06);
  min-width: 280px;
  padding: 1.25rem;
  z-index: 1000;
  animation: dropdownAppear 0.3s cubic-bezier(0.21, 1.02, 0.73, 1);
  transform-origin: top right;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(229, 231, 235, 0.8);
}

.user-info {
  display: flex;
  align-items: center;
  padding: 0.5rem 0 1.25rem;
}

.user-avatar-large {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: linear-gradient(135deg, #bd193b 0%, #ff385c 100%);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.4rem;
  margin-right: 1.25rem;
  border: 3px solid rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 12px rgba(189, 25, 59, 0.15);
}

.user-details {
  flex: 1;
}

.user-menu-container .user-name {
  font-weight: 700;
  color: white!important;
  font-size: 1.1rem;
  margin-bottom: 0.35rem;
  letter-spacing: -0.01em;
}

.user-menu-container .user-role {
  font-size: 0.9rem;
  color: #666;
  font-weight: 500;
}

.dropdown-divider {
  width: 80%;
  height: 1px;
  margin: 0.75rem auto;
  background: linear-gradient(to right,
      rgba(229, 231, 235, 0) 0%,
      rgba(189, 25, 59, 0.2) 50%,
      rgba(229, 231, 235, 0) 100%);
}

.dropdown-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem 0 0.25rem;
}

.user-menu-container .logout-button {
  position: relative;
  width: 85%;
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
  border: none;
  background: linear-gradient(135deg, #bd193b 0%, #ff385c 100%);
  color: white;
  font-weight: 600;
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-shadow: 0 4px 15px rgba(189, 25, 59, 0.2);
  overflow: hidden;
}

.user-menu-container .logout-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.logout-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(189, 25, 59, 0.25);
}

.logout-button:hover:before {
  transform: translateX(100%);
}

.logout-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 10px rgba(189, 25, 59, 0.2);
}

.logout-icon {
  font-size: 1.1rem;
  transition: transform 0.3s ease;
}

.logout-button:hover .logout-icon {
  transform: translateX(3px);
}

.logout-button:focus {
  animation: pulse 1.5s infinite;
  outline: none;
}

/* Animations */
@keyframes dropdownAppear {
  0% {
    opacity: 0;
    transform: scale(0.95) translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 4px 15px rgba(189, 25, 59, 0.2);
  }

  50% {
    box-shadow: 0 4px 25px rgba(189, 25, 59, 0.3);
  }

  100% {
    box-shadow: 0 4px 15px rgba(189, 25, 59, 0.2);
  }
}

/* Style responsive */
@media (max-width: 991px) {
  .user-dropdown {
    position: fixed;
    top: 35%;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-width: 100%;
    border-radius: 20px 20px 0 0;
    padding: 1.5rem;
    animation: slideUpMobile 0.3s cubic-bezier(0.21, 1.02, 0.73, 1);
    box-shadow: 0 -10px 30px rgba(0, 0, 0, 0.15);
  }

  .user-info {
    justify-content: center;
    text-align: center;
    padding: 1rem 0 1.5rem;
  }

  .user-avatar-large {
    width: 72px;
    height: 72px;
    font-size: 1.6rem;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .user-details {
    text-align: center;
  }

  .user-menu-container .logout-button {
    width: 90%;
    padding: 1rem 2rem;
    font-size: 1rem;
    margin: 0.5rem 0 1rem;
  }

  .user-menu-container .user-name {
    display: block;
  }

  .dropdown-actions {
    padding: 1rem 0 0.5rem;
  }

  @keyframes slideUpMobile {
    from {
      transform: translateY(100%);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .menu-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(4px);
    z-index: 999;
    animation: fadeIn 0.3s ease;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

/* Support des hover sur appareils tactiles */
@media (hover: none) {
  .logout-button:active {
    background: linear-gradient(135deg, #a31533 0%, #e6324f 100%);
  }

  .user-avatar:active {
    transform: scale(0.95);
  }
}

/* Styles d'accessibilité */
.user-avatar:focus-visible,
.logout-button:focus-visible {
  outline: 2px solid #bd193b;
  outline-offset: 2px;
}

/* Animation optimisée pour les appareils à haute performance */
@media (prefers-reduced-motion: no-preference) {
  .user-dropdown {
    transition: transform 0.3s cubic-bezier(0.21, 1.02, 0.73, 1),
      opacity 0.3s cubic-bezier(0.21, 1.02, 0.73, 1);
  }
}

/* Support du mode sombre */
@media (prefers-color-scheme: dark) {
  .user-dropdown {
    background-color: #1a1a1a;
    border-color: rgba(255, 255, 255, 0.1);
  }

  .user-name {
    color: #ffffff;
  }

  .user-role {
    color: #a0a0a0;
  }

  .dropdown-divider {
    background: linear-gradient(to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0) 100%);
  }
}

/* Ajoutez ces styles à UserMenu.css */

/* Indicateur de messages non lus sur l'avatar */
.user-avatar {
  position: relative;
}

.unread-indicator {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 12px;
  height: 12px;
  background-color: #3b82f6;
  border: 2px solid white;
  border-radius: 50%;
}

/* Style pour le bouton message avec compteur */
.message-button {
  position: relative;
}

.message-count {
  position: absolute;
  top: 2px;
  /* Ajusté de -8px à -6px */
  right: 2px;
  /* Ajusté de -8px à -4px */
  min-width: 18px;
  /* Réduit de 20px à 18px */
  height: 18px;
  /* Réduit de 20px à 18px */
  padding: 0 4px;
  background-color: #3b82f6;
  color: white;
  border-radius: 10px;
  font-size: 11px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Animation pour l'indicateur */
@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.unread-indicator {
  animation: pulse 2s infinite;
}

/* Ajustements pour le mode mobile */
@media (max-width: 768px) {
  .message-count {
    top: -6px;
    right: -6px;
    min-width: 18px;
    height: 18px;
    font-size: 11px;
  }
}