/* src/components/beautician/PortFolio.module.css */
:root {
  --primary: #bd193b;
  --primary-light: rgba(189, 25, 59, 0.1);
}

/* Container principal */
.portfolioContainer {
  height: calc(50vh - 35px);
  background-color: white;
  padding: 1.5rem;
  overflow: hidden;
}

.portfolioContent {
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.portfolioTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1rem;
  text-align: center;
}

/* Wrapper et Grid */
.portfolioWrapper {
  position: relative;
  flex: 1;
  min-height: 0;
  width: 100%;
}

.portfolioSlider {
  position: relative;
  height: 100%;
  width: 100%;
}

.portfolioGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  height: 100%;
  padding: 0.5rem;
  width: 100%;
}

/* Boutons de navigation */
.navButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  transition: all 0.3s ease;
}

.navButton:hover {
  background: white;
  transform: translateY(-50%) scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.prevButton {
  left: 0.5rem;
}

.nextButton {
  right: 0.5rem;
}

/* Items du portfolio */
.portfolioItem {
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 24vh;
  overflow: hidden;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: #f3f4f6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.imageWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.portfolioImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.portfolioOverlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.portfolioItem:hover .portfolioImage {
  transform: scale(1.05);
}

.portfolioItem:hover .portfolioOverlay {
  background: rgba(0, 0, 0, 0.4);
}

.eyeIcon {
  color: white;
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s ease;
}

.portfolioItem:hover .eyeIcon {
  opacity: 1;
  transform: scale(1);
}

/* Points de pagination */
.paginationDots {
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  z-index: 10;
}

.dot {
  width: 8px;
  height: 8px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
}

.dot.activeDot {
  width: 24px;
  border-radius: 4px;
  background-color: var(--primary);
}

/* Bouton Voir tout */
.viewAllButton {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 9999px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(189, 25, 59, 0.2);
}

.viewAllButton:hover {
  background-color: #a31533;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(189, 25, 59, 0.3);
}

/* Modal Gallery */
.modalOverlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContainer {
  position: relative;
  width: 90%;
  max-width: 1200px;
  height: 80vh;
  margin: 2rem;
}

.modalImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.modalNav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50%;
  padding: 0.75rem;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
}

.modalPrev {
  left: 1rem;
}

.modalNext {
  right: 1rem;
}

/* Adaptations Mobile */
@media (max-width: 768px) {
  .portfolioGrid {
    display: flex;
    padding: 0;
  }

  .mobileGrid {
    flex: 1;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
  }

  .portfolioItem {
    aspect-ratio: 4/5;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }

  .navButton {
    width: 36px;
    height: 36px;
    background: rgba(255, 255, 255, 0.95);
  }

  .prevButton {
    left: 0.25rem;
  }

  .nextButton {
    right: 0.25rem;
  }

  .paginationDots {
    bottom: 0.5rem;
    background: linear-gradient(to top, rgba(0,0,0,0.05), transparent);
    padding: 0.5rem 0;
  }

  .dot {
    width: 6px;
    height: 6px;
  }

  .dot.activeDot {
    width: 18px;
  }

  .viewAllButton {
    font-size: 0.9rem;
    padding: 0.625rem 1.25rem;
  }
}

@media (max-width: 480px) {
  .portfolioContainer {
    padding: 0.75rem;
  }

  .portfolioTitle {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }

  .navButton {
    width: 32px;
    height: 32px;
  }

  .portfolioItem {
    max-width: 320px;
  }

  .viewAllButton {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
}