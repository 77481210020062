/* À ajouter au début de ResultsColumn.css */
@media screen and (max-width: 576px) {
  .results-column {
    /* Empêcher le zoom automatique sur iOS */
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
    /* Désactiver le comportement de double-tap sur iOS */
    touch-action: manipulation;
  }

  .beauticians-grid {
    /* Empêcher le zoom sur les cartes */
    touch-action: pan-x pan-y;
    -webkit-touch-callout: none;
  }
  
  /* Ajuster la taille de la police pour qu'elle soit lisible sans zoom */
  /* .beautician-card-wrapper {
    font-size: 16px;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  } */
}

.results-column {
  background: white;
  border-radius: 20px;
  margin-left: 40px;
  padding: 1.5rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  height: calc(100vh - 30px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #bd193b #f1f1f1;
}

.results-column::-webkit-scrollbar {
  width: 6px;
}

.results-column::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.results-column::-webkit-scrollbar-thumb {
  background: #bd193b;
  border-radius: 3px;
}

.results-column::-webkit-scrollbar-thumb:hover {
  background: #a01532;
}

.results-header {
  margin-bottom: 1.5rem;
}

.results-header h2 {
  font-size: 1.2rem;
  color: #333;
  font-weight: 600;
}

.beauticians-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 1fr)) !important;
  gap: 1.5rem;
  width: 100%;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.beautician-card-wrapper {
  min-width: 150px;
  max-width: 300px;
  width: 100%;
  aspect-ratio: 2/3;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
}


.beautician-card-wrapper > * {
  width: 100%;
  height: 100%;
}


.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(189, 25, 59, 0.1);
}

.pagination-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.6rem 1rem;
  border: none;
  border-radius: 25px;
  background: white;
  color: #bd193b;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(189, 25, 59, 0.1);
  border: 1.5px solid rgba(189, 25, 59, 0.15);
}

.pagination-button:hover:not(:disabled) {
  background: #bd193b;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(189, 25, 59, 0.2);
}

.pagination-button:active:not(:disabled) {
  transform: translateY(0);
}

.pagination-button:disabled {
  background: #f5f5f5;
  color: #ccc;
  border-color: #eee;
  cursor: not-allowed;
  box-shadow: none;
}

.pagination-button.number {
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
}

.pagination-button.active {
  background: #bd193b;
  color: white;
  border: none;
  box-shadow: 0 4px 12px rgba(189, 25, 59, 0.2);
}

.pagination-ellipsis {
  color: #666;
  font-weight: 600;
  padding: 0 0.3rem;
}

@media (max-width: 1200px) {
  .beauticians-grid {
    gap: 1.25rem;
  }
}

@media (max-width: 992px) {
  .results-column {
    height: auto;
    min-height: 50vh;
    padding: 1.25rem;
    margin-left: 0px;
  }

  .beauticians-grid {
    gap: 1rem;
  }
}

@media (max-width: 768px) {
  .results-column {
    padding: 1rem;
    margin-left: 0px;
  }

  .beauticians-grid {
    gap: 0.75rem;
  }

  .results-header {
    margin-bottom: 1rem;
  }

  .results-header h2 {
    font-size: 1rem;
  }

  .pagination {
    gap: 0.3rem;
    margin-top: 1.5rem;
    padding-top: 1.25rem;
    flex-wrap: wrap;
  }

  .pagination-button {
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem;
    gap: 0.3rem;
  }

  .pagination-button.number {
    width: 35px;
    height: 35px;
  }
  
  /* Cache certains numéros de page sur mobile */
  .pagination-button.number:not(.active) {
    display: none;
  }
  
  /* Toujours montrer le premier et le dernier */
  .pagination-button.number:first-child,
  .pagination-button.number:last-child,
  .pagination-button.active {
    display: flex;
  }
  
  .pagination-ellipsis {
    display: none;
  }
}

@media (max-width: 576px) {
  .results-column {
    padding: 0.75rem;
  }

  .beauticians-grid {
    gap: 0.5rem;
  }

  .pagination {
    gap: 0.25rem;
    margin-top: 1.25rem;
    padding-top: 1rem;
  }

  .pagination-button {
    padding: 0.4rem 0.7rem;
    font-size: 0.75rem;
  }

  .pagination-button.number {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 360px) {
  .results-column {
    padding: 0.5rem;
  }

  .beauticians-grid {
    gap: 0.4rem;
  }

  .results-header h2 {
    font-size: 0.9rem;
  }
}

/* Styles de pagination */
.pagination {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .pagination {
    gap: 0.25rem;
    margin-top: 1.25rem;
    padding-top: 1rem;
  }

  .pagination-button {
    padding: 0.4rem 0.7rem;
    font-size: 0.75rem;
  }

  .pagination-button.number {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 576px) {
  .pagination {
    gap: 0.25rem;
    margin-top: 1.25rem;
    padding-top: 1rem;
  }

  .pagination-button {
    padding: 0.4rem 0.7rem;
    font-size: 0.75rem;
  }

  .pagination-button.number {
    width: 32px;
    height: 32px;
  }
}