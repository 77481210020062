.login-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050!important;
  backdrop-filter: blur(5px);
  /* Empêche le scroll */
  overflow: hidden;
}

.login-modal .modal-content {
  background: white !important;
  border-radius: 20px !important;
  padding: 2.5rem;
  width: 100%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  animation: modalAppear 0.3s ease-out;
}

/* Nouveau conteneur spécifique pour le logo */
.login-modal .modal-logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: -1rem;
}

.login-modal .modal-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-modal .modal-logo img {
  width: 60px; /* Réduit de 300px à 120px (40%) */
  height: auto;
  max-width: 100%;
  object-fit: contain;
}

.login-modal .modal-title {
  text-align: center;
  color: #333;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.login-modal .login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.login-modal .form-group {
  position: relative;
}

.login-modal .form-group input {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: #f8f9fa;
}

.login-modal .form-group input:focus {
  border-color: #bd193b;
  background: white;
  outline: none;
  box-shadow: 0 0 0 3px rgba(189, 25, 59, 0.1);
}

.login-modal .modal-close-btn {
  position: fixed; /* Changé à fixed pour garantir la position */
  bottom: 20px;
  left: 20px;
  width: auto;
  height: 40px;
  padding: 0 25px;
  background: #bd193b;
  color: white;
  border: none;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1060;
  font-size: 16px;
  font-weight: 500;
  gap: 8px;
  box-shadow: 0 4px 12px rgba(189, 25, 59, 0.2);
}

.login-modal .modal-close-btn:hover {
  background: #a11532;
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(189, 25, 59, 0.3);
}

.login-modal .modal-close-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(189, 25, 59, 0.2);
}

/* Icône close */
.login-modal .modal-close-btn .close-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  position: relative;
}

.login-modal .modal-close-btn .close-icon::before,
.login-modal .modal-close-btn .close-icon::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 2px;
  background-color: white;
  transition: transform 0.3s ease;
}

.login-modal .modal-close-btn .close-icon::before {
  transform: rotate(45deg);
}

.login-modal .modal-close-btn .close-icon::after {
  transform: rotate(-45deg);
}

.login-modal .modal-close-btn:hover .close-icon::before {
  transform: rotate(225deg);
}

.login-modal .modal-close-btn:hover .close-icon::after {
  transform: rotate(135deg);
}

.login-modal .form-links {
  display: flex;
  justify-content: flex-end;
  font-size: 0.9rem;
}

.login-modal .forgot-password {
  cursor: pointer;
  color: #ed214a;
  text-decoration: none;
  transition: color 0.2s ease;
}

.login-modal .forgot-password:hover {
  color: #bd193b;
}

.login-modal .login-button {
  background-color: #bd193b;
  align-items: center;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 0.3rem;
}

.login-modal .login-button:hover {
  background-color: #a11532;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(189, 25, 59, 0.2);
}

.login-modal .signup-link {
  text-align: center;
  margin-top: 0.8rem;
  font-size: 0.9rem;
  color: #353535;
}

.login-modal .signup-link a {
  color: #bd193b;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.login-modal .signup-link a:hover {
  color: #a11532;
}

@media (max-width: 480px) {
  .login-modal .modal-content {
    padding: 2rem 1.5rem;
    margin: 1rem;
  }

  .login-modal .modal-logo img {
    width: 40px; /* Réduit de 200px à 80px (40%) */
  }
}

/* Ajoutez ces styles à vos fichiers CSS existants */

.login-modal .link-button {
  background: none;
  border: none;
  padding: 0;
  color: #bd193b;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.2s ease;
}

.login-modal .link-button:hover {
  color: #a11532;
  text-decoration: underline;
}

/* Pour éviter l'outline par défaut des boutons tout en gardant l'accessibilité */
.login-modal .link-button:focus {
  outline: 2px solid #bd193b;
  outline-offset: 2px;
  border-radius: 2px;
}

.login-modal .link-button:focus:not(:focus-visible) {
  outline: none;
}
