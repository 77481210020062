.not-found {
    min-height: calc(100vh - 140px);
    display: flex;
    align-items: center;
    background: linear-gradient(135deg, #fff5f6 0%, #ffe4e7 100%);
    padding: 3rem 0;
  }
  
  .not-found-content {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .error-code {
    font-size: 120px;
    font-weight: 700;
    color: #bd193b;
    line-height: 1;
    text-shadow: 2px 2px 0 #fff;
    margin-bottom: 1rem;
  }
  
  .not-found h1 {
    color: #333;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .not-found p {
    color: #666;
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }
  
  .illustration {
    margin: 3rem 0;
  }
  
  .mirror {
    width: 120px;
    height: 180px;
    margin: 0 auto;
    position: relative;
  }
  
  .mirror-frame {
    width: 100%;
    height: 100%;
    border: 8px solid #bd193b;
    border-radius: 60px;
    position: relative;
    background: white;
  }
  
  .mirror-reflection {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 60%;
    height: 60%;
    background: rgba(189, 25, 59, 0.1);
    border-radius: 50%;
    transform: rotate(-45deg);
  }
  
  .actions {
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .action-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.8rem 1.5rem;
    border-radius: 25px;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  
  .action-button.home {
    background: #bd193b;
    color: white;
  }
  
  .action-button.search {
    background: white;
    color: #bd193b;
    border: 2px solid #bd193b;
  }
  
  .action-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(189, 25, 59, 0.2);
  }
  
  @media (max-width: 768px) {
    .error-code {
      font-size: 80px;
    }
  
    .not-found h1 {
      font-size: 1.5rem;
    }
  
    .actions {
      flex-direction: column;
      padding: 0 1rem;
    }
  
    .action-button {
      width: 100%;
      justify-content: center;
    }
  }