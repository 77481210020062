/* Z-index for the second modal */
.second-modal .modal-dialog {
    z-index: 1056 !important; /* Higher than the first modal (Bootstrap's default is 1050) */
}

/* Z-index for the second modal backdrop */
.second-modal-backdrop {
    z-index: 1055 !important; /* Slightly lower than the modal to sit under it */
}

/* Optional: dim the backdrop more for the second modal */
.second-modal-backdrop.show {
    opacity: 0.8 !important;
}
