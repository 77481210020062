.dashboard {
    padding: 2rem;
    background-color: #f8fafc;
  }
  
  .dashboard-wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Header Styles */
  .dashboard-header {
    margin-bottom: 2rem;
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .header-content h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1e293b;
    margin: 0 0 0.5rem 0;
  }
  
  .today-date {
    color: #64748b;
    font-size: 0.875rem;
  }
  
  /* KPI Grid */
  .kpi-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .kpi-card {
    background: white;
    border-radius: 12px;
    padding: 1.25rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }
  
  .kpi-card:hover {
    transform: translateY(-2px);
  }
  
  .kpi-icon {
    padding: 0.75rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .kpi-info {
    flex: 1;
  }
  
  .kpi-title {
    font-size: 0.875rem;
    color: #64748b;
    display: block;
    margin-bottom: 0.25rem;
  }
  
  .kpi-stats {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
  }
  
  .kpi-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1e293b;
  }
  
  .kpi-trend {
    font-size: 0.875rem;
    font-weight: 500;
    color: #059669;
  }
  
  .dashboard-main {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 1.5rem;
    max-width: 1200px; /* ou la largeur que vous souhaitez */
    margin: 0 auto;
    width: 100%;
  }
  
  .dashboard-section {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: border-box;
  }
  
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .section-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #1e293b;
  }
  
  .section-title h2 {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0;
  }
  
  .view-all-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    background: white;
    color: #64748b;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .view-all-button:hover {
    background: #f8fafc;
    color: #1e293b;
    border-color: #cbd5e1;
  }
  
  /* Appointments List */
/* Ajustez la section des rendez-vous */
.appointments-section {
    width: 100%;
    box-sizing: border-box;
  }
  
  .appointments-list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;
    max-width: 100%; /* Assurez-vous que la liste ne dépasse pas son conteneur */
  }
  
  .appointment-card {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 8px;
    background: #f8fafc;
    transition: all 0.2s ease;
    border-left: 3px solid transparent;
    width: 100%;
    max-width: 100%; /* Empêche la carte de dépasser */
    box-sizing: border-box; /* Inclut padding et bordure dans la largeur */
  }
  
  .appointment-card.confirmed {
    border-left-color: #059669;
  }
  
  .appointment-card.pending {
    border-left-color: #eab308;
  }
  
  .appointment-card:hover {
    background: #f1f5f9;
  }
  
  .appointment-time {
    font-size: 0.875rem;
    font-weight: 600;
    color: #1e293b;
    padding: 0.5rem 0.75rem;
    background: white;
    border-radius: 6px;
    min-width: 60px;
    text-align: center;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .appointment-info {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  
  .client-name {
    font-weight: 500;
    color: #1e293b;
    margin-bottom: 0.25rem;
  }
  
  .service-details {
    font-size: 0.875rem;
    color: #64748b;
  }
  
  .appointment-status {
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    background: #f0fdf4;
    color: #059669;
  }
  
  .appointment-card.pending .appointment-status {
    background: #fefce8;
    color: #ca8a04;
  }
  
  /* Stats Grid */
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 1rem;
  }
  
  .stat-card {
    padding: 1.25rem;
    background: #f8fafc;
    border-radius: 8px;
    text-align: center;
    transition: all 0.2s ease;
  }
  
  .stat-card:hover {
    background: #f1f5f9;
  }
  
  .stat-icon {
    color: #64748b;
    margin-bottom: 0.5rem;
  }
  
  .stat-title {
    font-size: 0.875rem;
    color: #64748b;
    margin-bottom: 0.5rem;
  }
  
  .stat-value {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1e293b;
    margin-bottom: 0.25rem;
  }
  
  .stat-trend {
    font-size: 0.875rem;
    font-weight: 500;
    display: inline-block;
    padding: 0.125rem 0.5rem;
    border-radius: 9999px;
  }
  
  .stat-trend.positive {
    background: #f0fdf4;
    color: #059669;
  }
  
  .stat-trend.neutral {
    background: #f1f5f9;
    color: #64748b;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .dashboard-main {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 768px) {
    .dashboard {
      padding: 1rem;
    }
  
    .kpi-grid {
      grid-template-columns: 1fr;
    }
  
    .stats-grid {
      grid-template-columns: 1fr;
    }
  
    .header-content {
      flex-direction: column;
      gap: 0.5rem;
    }
  }
  
  @media (max-width: 640px) {
    .appointment-card {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .appointment-status {
      align-self: flex-start;
    }
  }