.footer {
    background-color: #f8f9fa;
    padding: 4rem 0 2rem;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  .home-footer {
    z-index: 2 !important;
  }
  
  .footer h5 {
    color: #333;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    position: relative;
    padding-bottom: 0.5rem;
  }
  
  .footer h5::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30px;
    height: 2px;
    background-color: #bd193b;
    border-radius: 2px;
  }
  
  /* Style des liens */
  .footer ul li {
    margin-bottom: 0.8rem;
  }
  
  .footer ul li a {
    color: #666;
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
    padding-left: 0;
  }
  
  .footer ul li a::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 0;
    height: 1px;
    background-color: #bd193b;
    transition: width 0.3s ease;
  }
  
  .footer ul li a:hover {
    color: #bd193b;
    padding-left: 5px;
  }
  
  .footer ul li a:hover::before {
    width: 100%;
  }
  
  /* Text muted personnalisé */
  .footer .text-muted {
    color: #666 !important;
    line-height: 1.6;
  }
  
  /* Style du formulaire newsletter */
  .footer form {
    margin-top: 1rem;
  }
  
  .footer .form-control {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    padding: 0.6rem 1.2rem;
    background: white;
    transition: all 0.3s ease;
  }
  
  .footer .form-control:focus {
    border-color: #bd193b;
    box-shadow: none;
  }
  
  .footer .btn-primary {
    background-color: #bd193b;
    border-color: #bd193b;
    border-radius: 50px;
    padding: 0.6rem 1.5rem;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  
  .footer .btn-primary:hover {
    background-color: #961530;
    border-color: #961530;
    transform: translateY(-2px);
  }
  
  /* Séparateur */
  .footer hr {
    margin: 2rem 0;
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.5;
  }
  
  /* Copyright */
  .footer .copyright {
    font-size: 0.9rem;
  }
  
  /* Animation des liens */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-10px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .footer ul li {
    animation: slideIn 0.3s ease forwards;
    animation-delay: calc(0.1s * var(--i));
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer {
      padding: 3rem 0 1.5rem;
    }
  
    .footer .col-md-2,
    .footer .col-md-4 {
      margin-bottom: 2rem;
    }
  
    .footer form {
      flex-direction: column;
      gap: 1rem;
    }
  
    .footer .btn-primary {
      width: 100%;
    }
  
    .footer h5 {
      margin-bottom: 1rem;
    }
  }
  
  @media (max-width: 576px) {
    .footer {
      text-align: center;
    }
  
    .footer h5::after {
      left: 50%;
      transform: translateX(-50%);
    }
  
    .footer ul li a::before {
      display: none;
    }
  
    .footer ul li a:hover {
      padding-left: 0;
    }
  }