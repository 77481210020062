/* Variables globales */
:root {
  --primary: #bd193b;
  --primary-light: rgba(189, 25, 59, 0.1);
  --primary-dark: #a00f2f;
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-500: #6b7280;
  --gray-700: #374151;
  --gray-900: #111827;
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  --radius-md: 8px;
  --transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Conteneur principal */
.filters-column {
  display: flex;
  flex-direction: column;
  height: 100vh;
  border-right: 1px solid #e2e8f0;
  background: white;
  width: 320px;
  min-width: 320px;
}

.search-box:focus-within {
  border-color: var(--primary);
  background: white;
  box-shadow: 0 0 0 2px var(--primary-light);
}

.search-box svg {
  color: var(--primary);
  flex-shrink: 0;
}

.search-box input {
  border: none;
  outline: none;
  width: 100%;
  background: transparent;
  font-size: 0.875rem;
  color: var(--gray-900);
  padding-right: 0.5rem;
}

/* Nouveau style pour la search-box dans FiltersColumn */
.filters-column .search-box-container {
  padding: 1.25rem;
  border-bottom: 1px solid var(--gray-200);
  position: relative;
  z-index: 10;
  background: white;
  transition: all 0.3s ease;
}

.filters-column .search-input-section {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  border: 2px solid var(--gray-200);
  border-radius: 12px;
  background: var(--gray-50);
  transition: all 0.2s ease;
}

.filters-column .search-input-section:focus-within {
  border-color: var(--primary);
  background: white;
  box-shadow: 0 0 0 4px var(--primary-light);
  transform: translateY(-1px);
}

.filters-column .search-input-section svg {
  color: var(--gray-500);
  transition: color 0.2s ease;
}

.filters-column .search-input-section:focus-within svg {
  color: var(--primary);
}

.filters-column .search-input {
  width: 100%;
  border: none;
  background: transparent;
  font-size: 0.9375rem;
  color: var(--gray-900);
  outline: none;
}

.filters-column .search-input::placeholder {
  color: var(--gray-500);
  transition: color 0.2s ease;
}

.filters-column .search-input:focus::placeholder {
  color: var(--gray-400);
}

/* Animation pour les suggestions */
.filters-column .suggestions-dropdown {
  margin-top: 0.5rem;
  border: none;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  animation: slideDown 0.3s ease;
  overflow: hidden;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.filters-column .suggestion-item {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid var(--gray-100);
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filters-column .suggestion-item:hover {
  background: var(--gray-50);
  transform: translateX(4px);
}

.filters-column .suggestion-item.selected {
  background: var(--primary-light);
}

.filters-column .suggestion-content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.filters-column .suggestion-text {
  color: var(--gray-900);
  font-weight: 500;
}

.filters-column .suggestion-subtext {
  color: var(--gray-500);
  font-size: 0.8125rem;
}

.filters-column .suggestion-type {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  background: var(--primary-light);
  color: var(--primary);
  border-radius: 4px;
  font-weight: 500;
}

/* Style amélioré pour le bouton de réinitialisation */
.reset-filters-button {
  width: calc(100% - 2rem);
  margin: 1rem;
  padding: 0.875rem;
  background: linear-gradient(to right, var(--primary), var(--primary-dark));
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  font-size: 0.9375rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  box-shadow: 0 2px 8px rgba(189, 25, 59, 0.2);
}

.reset-filters-button:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transform: translate(-50%, -50%) scale(0);
  border-radius: 50%;
  transition: transform 0.5s ease;
}

.reset-filters-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(189, 25, 59, 0.3);
}

.reset-filters-button:hover:before {
  transform: translate(-50%, -50%) scale(1.5);
}

.reset-filters-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(189, 25, 59, 0.2);
}

/* Animation pour le texte du bouton */
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-2px); }
  75% { transform: translateX(2px); }
}

.reset-filters-button:hover span {
  animation: shake 0.3s ease-in-out;
}

/* Zone de défilement des filtres */
.filters-scroll {
  flex: 1;
  overflow-y: auto;
  padding: 0 1rem 1rem 1rem;
}

.filters-scroll::-webkit-scrollbar {
  width: 4px;
}

.filters-scroll::-webkit-scrollbar-track {
  background: var(--gray-100);
}

.filters-scroll::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 2px;
}

/* Suggestions dropdown */
.suggestions-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  z-index: 50;
}

.suggestion-item {
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  transition: var(--transition);
}

.suggestion-item:hover {
  background: var(--gray-50);
}

.clear-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 0.25rem;
  cursor: pointer;
  color: var(--gray-500);
  border-radius: 50%;
  transition: all 0.2s ease;
}

.clear-button:hover {
  color: var(--primary);
  background: var(--primary-light);
}

.clear-button:active {
  transform: scale(0.95);
}

/* Filters.css */
.rating-filter {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.rating-option {
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.25rem;
  transition: background-color 0.2s;
}

.rating-option:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.rating-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.rating-label input[type="radio"] {
  display: none;
}

.stars {
  display: flex;
  align-items: center;
  gap: 2px;
}

.star {
  color: #e2e8f0;
  width: 16px;
  height: 16px;
}

.star.filled {
  color: #bd193b;
}

.rating-text {
  margin-left: 0.5rem;
  font-size: 0.875rem;
  color: #64748b;
}

/* Ajout pour la barre de défilement */

.filters-scroll {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

.filters-scroll::-webkit-scrollbar {
  width: 8px;
}

.filters-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.filters-scroll::-webkit-scrollbar-thumb {
  background: #bd193b;
  border-radius: 4px;
}

.filters-scroll::-webkit-scrollbar-thumb:hover {
  background: #bd193b;
}

/* Styles pour les filtres de budget */
.price-checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
}

.price-checkbox .count {
  margin-left: auto;
  color: #64748b;
  font-size: 0.875rem;
  background: #f1f5f9;
  padding: 0.125rem 0.5rem;
  border-radius: 1rem;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .reset-filters-button {
    width: calc(100% - 1rem);
    margin: 0.5rem;
    padding: 0.75rem;
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 480px) {
  .filters-column {
    width: 100%;
    min-width: auto;
  }

  .reset-filters-button {
    width: calc(100% - 1rem);
    margin: 0.5rem;
    padding: 0.625rem;
    font-size: 0.8125rem;
  }
}
