.hero-wrapper {
  position: relative;
  height: 115vh;
  overflow: hidden;
}

.hero-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(115vh - 70px);
  background-size: cover;
  background-position: center 30%;
  background-repeat: no-repeat;
  z-index: 1;
}
  
  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0.1) 100%
    );
  }
  
  .hero {
    z-index: 2;
    @apply h-full relative text-white flex items-center justify-center;
  }
  
  .hero .container {
    transform: translateY(60px);
    @apply flex items-center justify-center;
  }
  
  .hero-content {
    position: relative;
    padding: 2.8rem;
  }
  
.hero h1 {
  color: white;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 2.5rem;
}
  
  .hero .lead {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.25rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    margin-bottom: 2rem;
  }
  
  /* Ajustements pour la barre de recherche dans le contexte du hero */
  .hero .search-container {
    margin-top: 2rem; /* Réduit de 2.5rem à 2rem */
  }
  
  .hero .search-input-wrapper {
    background: rgba(255, 255, 255, 0.95);
  }
  
  .hero .search-suggestions {
    background: rgba(255, 255, 255, 0.95);
    z-index: 1000;
  }
  


@media (max-width: 920px) {
  .navbar {
    height: 70px;
    background-color: transparent;
  }

  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 1rem 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }


  .hero h1 {
    font-size: 1.875rem; /* 75% de 2.5rem */
  }

  /* Garder le fond blanc uniquement pour les pages spécifiques */
  body[data-page="search"] .navbar,
  body[data-page="beautician"] .navbar,
  body[data-page="booking"] .navbar,
  body[data-page="payment"] .navbar,
  body[data-page="contact"] .navbar {
    background-color: rgba(255, 255, 255, .95);
  }

  /* Page d'accueil - header transparent */
  body:not([data-page]) .navbar {
    background-color: transparent;
  }

  /* Styles du menu burger */
  .navbar-toggler {
    border: none;
    padding-right: 15px;
  }

  .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
  }
  .hero .container {
    transform: translateY(15px);
    @apply flex items-center justify-center;
  }

  .hero-background {
    background-image: url('../../images/hero/hero-responsives.jpg') !important;
    height: 100vh;
    background-position: center center;
  }
  
  .hero-wrapper {
    height: 100vh;
  }
}