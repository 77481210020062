/* Variables globales */
:root {
  --primary: #bd193b;
  --primary-light: rgba(189, 25, 59, 0.1);
  --primary-dark: #a00f2f;
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-500: #6b7280;
  --gray-700: #374151;
  --gray-900: #111827;
}

/* Réinitialisation de base */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: default; /* Ajout du curseur par défaut */
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--gray-50);
  color: var(--gray-900);
  user-select: none; /* Désactive la sélection par défaut */
}

/* Styles pour les éléments interactifs */
input, 
textarea, 
[contenteditable="true"],
.selectable {
  user-select: text;
  cursor: text;
}

/* Le reste de votre CSS reste inchangé... */

/* Styles pour le conteneur principal */
.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Styles pour le contenu principal */
.main-content {
  flex: 1;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

/* Utilitaires */
.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.3s ease-in;
}

.show {
  visibility: visible;
}

/* Media Queries */
@media (max-width: 1024px) {
  .container {
    max-width: 100%;
    padding: 0 2rem;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0 1rem;
  }
}

/* Accessibilité */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Styles des scrollbars personnalisés */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--gray-100);
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-dark);
}

/* Styles pour les transitions de pages */
.page-transition {
  position: relative;
  transition: opacity 0.3s ease-in-out;
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
}

/* Cibler le modal pour les petits écrans */
@media (max-width: 576px) {
  .modal-dialog {
    margin: 0;
    max-width: 100%;
  }

  .modal-content {
    border-radius: 0;
    /* min-height: 100vh; */
    padding: 0.5rem; /* Ajustez selon vos besoins */
  }

  .modal-header {
    padding: 0.5rem; /* Réduire le padding du header */
  }

  .modal-body {
    padding: 0.75rem; /* Réduire le padding du body */
  }

  .modal-footer {
    padding: 0.5rem; /* Réduire le padding du footer */
  }
}