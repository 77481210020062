  /* Colonne de la carte */
  .map-column {
    background: white;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    height: 100%;
    position: sticky;
    top: 85px;
  }
  