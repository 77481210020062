/* BeauticianServices.css */
.services-manager {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  /* Header Styles */
  .services-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    background: white;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .header-content {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  
  .header-content h1 {
    margin: 0;
    font-size: 1.5rem;
    color: var(--gray-900);
  }
  
  .add-category-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: var(--primary);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .add-category-button:hover {
    background-color: var(--primary-hover);
  }
  
  /* Category Styles */
  .service-category {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .category-header h3 {
    margin: 0;
    font-size: 1.25rem;
    color: var(--gray-900);
  }
  
  .expand-button {
    padding: 0.5rem 1rem;
    background: none;
    border: 1px solid var(--gray-200);
    border-radius: 6px;
    color: var(--gray-700);
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .expand-button:hover {
    background: var(--gray-50);
    border-color: var(--gray-300);
  }
  
  /* Services Grid */
  .prestations-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  /* Service Card Styles */
  .service-card {
    background: var(--gray-50);
    border-radius: 12px;
    overflow: hidden;
    transition: all 0.2s;
  }
  
  .service-card:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
  
  .service-card-header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: start;
    border-bottom: 1px solid var(--gray-200);
  }
  
  .service-card-header h4 {
    margin: 0;
    color: var(--gray-900);
    font-size: 1.125rem;
  }
  
  .service-actions {
    display: flex;
    gap: 0.5rem;
  }
  
  .action-button {
    padding: 0.5rem;
    background: none;
    border: none;
    border-radius: 6px;
    color: var(--gray-600);
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .action-button:hover {
    background: white;
    color: var(--gray-900);
  }
  
  .action-button.delete:hover {
    color: var(--primary);
    background: var(--primary-light);
  }
  
  .service-details {
    padding: 1rem;
  }
  
  .service-info {
    margin-bottom: 1rem;
  }
  
  .service-description {
    color: var(--gray-700);
    margin: 0 0 1rem 0;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .service-specs {
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
  }
  
  .spec-item {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    color: var(--gray-700);
    font-size: 0.875rem;
  }
  
  /* Image Slider Styles */
  .prestation-images-container {
    margin-top: 1rem;
  }
  
  .images-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .image-item {
    aspect-ratio: 16/9;
    overflow: hidden;
    border-radius: 8px;
  }
  
  .prestation-images {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .slider-dots {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .dot {
    width: 8px;
    height: 8px;
    border: none;
    border-radius: 50%;
    background: var(--gray-300);
    cursor: pointer;
    padding: 0;
  }
  
  .dot.active {
    background: var(--primary);
  }
  
  .no-images {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background: var(--gray-100);
    border-radius: 8px;
    color: var(--gray-500);
    gap: 0.5rem;
  }
  
  /* Add Service Card */
  .add-service-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 2rem;
    background: white;
    border: 2px dashed var(--gray-300);
    border-radius: 12px;
    color: var(--gray-600);
    cursor: pointer;
    transition: all 0.2s;
    min-height: 15rem;
  }
  
  .add-service-card:hover {
    background: var(--gray-50);
    border-color: var(--primary);
    color: var(--primary);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .services-manager {
      padding: 1rem;
    }
  
    .prestations-grid {
      grid-template-columns: 1fr;
    }
  
    .header-content {
      flex-direction: column;
      align-items: stretch;
      gap: 1rem;
    }
  
    .service-card-header {
      flex-direction: column;
      gap: 1rem;
    }
  
    .service-actions {
      width: 100%;
      justify-content: flex-end;
    }
  }