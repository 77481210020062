/* Styles existants */
.booking-page {
  min-height: calc(100vh - 80px);
  background-color: var(--gray-50);
  padding: 2rem;
}

.booking-steps {
  max-width: 800px;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 1rem;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}

.step-number {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--gray-200);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray-600);
  font-weight: 500;
  margin-bottom: 0.5rem;
  transition: all 0.3s ease;
}

.step.active .step-number {
  background-color: var(--primary);
  color: white;
}

.step-text {
  font-size: 0.9rem;
  color: var(--gray-600);
  transition: all 0.3s ease;
}

.step.active .step-text {
  color: var(--gray-900);
  font-weight: 500;
}

.step-line {
  flex: 1;
  height: 2px;
  background-color: var(--gray-200);
  margin: 0 1rem;
  position: relative;
  top: -16px;
}

.booking-content {
  max-width: 800px;
  margin: 0 auto;
}

.booking-confirmation {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.booking-summary {
  margin-top: 2rem;
}

.services-summary, .datetime-summary {
  margin-bottom: 2rem;
}

.services-summary h3, .datetime-summary h3 {
  color: var(--gray-900);
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--gray-200);
}

.service-item {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
  border-bottom: 1px solid var(--gray-100);
}

.total-line {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 2px solid var(--gray-200);
  font-weight: 600;
}

.total-amount {
  color: var(--primary);
}

/* Nouveaux styles pour l'authentification */
.auth-required {
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  margin-top: 2rem;
  border: 1px solid var(--gray-200);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.auth-required p {
  color: var(--gray-700);
  margin-bottom: 1.5rem;
}

.auth-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.login-button,
.register-button {
  padding: 0.875rem 1.5rem;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-button {
  background-color: var(--primary);
  color: white;
}

.login-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(189, 25, 59, 0.2);
}

.register-button {
  background-color: var(--gray-200);
  color: var(--gray-700);
}

.register-button:hover {
  background-color: var(--gray-300);
  transform: translateY(-2px);
}

/* Nouveaux styles pour les options de paiement */
.payment-options {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid var(--gray-200);
}

.payment-options h3 {
  color: var(--gray-900);
  margin-bottom: 1.5rem;
}

.payment-choices {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.payment-choice {
  background: white;
  border: 2px solid var(--gray-200);
  border-radius: 8px;
  padding: 1.25rem;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;
}

.payment-choice:hover {
  border-color: var(--gray-300);
  background-color: var(--gray-50);
}

.payment-choice.selected {
  border-color: var(--primary);
  background-color: var(--gray-50);
}

.choice-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.choice-content strong {
  color: var(--gray-900);
  font-size: 1rem;
}

.choice-content span {
  color: var(--primary);
  font-weight: 600;
  font-size: 1.125rem;
}

.choice-content small {
  color: var(--gray-600);
  font-size: 0.875rem;
}

/* Mise à jour du bouton de confirmation */
.confirm-button {
  width: 100%;
  padding: 1rem;
  margin-top: 2rem;
  background: var(--primary);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.confirm-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(189, 25, 59, 0.2);
}

.confirm-button:disabled {
  background-color: var(--gray-300);
  transform: none;
  box-shadow: none;
  cursor: not-allowed;
}

.return-button {
  width: 100%;
  padding: 0.85rem;
  margin-top: 1rem;
  background: var(--gray-600);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
}

.return-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(36, 36, 36, 0.2);
}

/* Media Queries */
@media (max-width: 768px) {
  .booking-page {
    padding: 1rem;
  }

  .booking-steps {
    padding: 0;
  }

  .step-text {
    font-size: 0.8rem;
  }

  .booking-confirmation {
    padding: 1.5rem;
  }

  .auth-buttons {
    flex-direction: column;
  }

  .payment-choice {
    padding: 1rem;
  }
}