.plani-beauty-pro-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background-image: url('../../assets/images/planibeauty-pro.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	text-align: center;
}

.plani-beauty-pro-container h1 {
	font-size: 2.3rem;
	font-weight: 400;
	margin-bottom: 0.5rem;
}

.plani-beauty-pro-container h2 {
	font-size: 3.2rem;
	font-weight: 500;
	margin-bottom: 2rem;
}

.plani-beauty-pro-container .button-group {
	margin-top: 3rem;
	display: flex;
	gap: 1rem;
}

.plani-beauty-pro-container .button {
	padding: 0.8rem 1.5rem;
	font-size: 1rem;
	border: none;
	border-radius: 25px;
	cursor: pointer;
	background-color: rgba(255, 255, 255, 0.8);
	color: #000000;
	transition: background-color 0.3s;
	text-transform: uppercase;
	min-width: 20rem;
}

.plani-beauty-pro-container .button:hover {
	background-color: rgba(224, 224, 224, 0.8);
}

.plani-beauty-pro-container .button.active {
	background-color: rgba(0, 0, 0, 0.8);
	color: #ffffff;
}

/* Media query for tablets */
@media (max-width: 768px) {
	.plani-beauty-pro-container h1 {
		font-size: 2rem;
	}

	.plani-beauty-pro-container h2 {
		font-size: 2.5rem;
	}

	.plani-beauty-pro-container .button {
		min-width: auto;
		font-size: 0.9rem;
	}
}

/* Media query for mobile devices */
@media (max-width: 480px) {
	.plani-beauty-pro-container h1 {
		font-size: 1.8rem;
	}

	.plani-beauty-pro-container h2 {
		font-size: 2rem;
	}

	.plani-beauty-pro-container .button {
		padding: 0.6rem 1rem;
		min-width: 12rem;
		font-size: 0.8rem;
	}

	.plani-beauty-pro-container .button-group {
		flex-direction: column;
		align-items: center;
	}
}