/* BeauticianStats.css */
.stats-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  /* Header Styles */
  .stats-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .stats-header h1 {
    margin: 0;
    font-size: 1.5rem;
    color: var(--gray-900);
  }
  
  .timeframe-selector {
    position: relative;
    width: 200px;
  }
  
  .timeframe-select {
    width: 100%;
    padding: 0.5rem 2.5rem 0.5rem 1rem;
    border: 1px solid var(--gray-200);
    border-radius: 8px;
    background: white;
    appearance: none;
    font-size: 0.875rem;
    color: var(--gray-700);
    cursor: pointer;
  }
  
  .select-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--gray-500);
    pointer-events: none;
  }
  
  /* KPI Grid */
  .kpi-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .kpi-card {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .kpi-icon {
    padding: 0.75rem;
    background-color: var(--primary-light);
    border-radius: 10px;
    color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .kpi-info {
    flex: 1;
  }
  
  .kpi-title {
    font-size: 0.875rem;
    color: var(--gray-600);
    display: block;
    margin-bottom: 0.25rem;
  }
  
  .kpi-stats {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
  }
  
  .kpi-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--gray-900);
  }
  
  .kpi-change {
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .kpi-change.up {
    color: #059669;
  }
  
  .kpi-change.down {
    color: var(--primary);
  }
  
  /* Charts Grid */
  .charts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 1.5rem;
  }
  
  .chart-card {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .chart-header h2 {
    margin: 0;
    font-size: 1.25rem;
    color: var(--gray-900);
  }
  
  .chart-content {
    position: relative;
  }
  
  /* Legend Styles */
  .chart-legend {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid var(--gray-200);
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
  }
  
  .legend-color {
    width: 12px;
    height: 12px;
    border-radius: 3px;
  }
  
  .legend-label {
    color: var(--gray-700);
    flex: 1;
  }
  
  .legend-value {
    font-weight: 500;
    color: var(--gray-900);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .stats-container {
      padding: 1rem;
    }
  
    .stats-header {
      flex-direction: column;
      align-items: stretch;
      gap: 1rem;
    }
  
    .timeframe-selector {
      width: 100%;
    }
  
    .charts-grid {
      grid-template-columns: 1fr;
    }
  
    .chart-card {
      padding: 1rem;
    }
  }