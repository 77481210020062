/* Variables globales */
:root {
  --primary: #bd193b;
  --primary-light: rgba(189, 25, 59, 0.1);
  --primary-dark: #a00f2f;
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-500: #6b7280;
  --gray-700: #374151;
  --gray-900: #111827;
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  --radius-md: 8px;
  --transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Container des filtres */
.filters-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
}

/* Section de filtre */
.filter-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.filter-section h3 {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--gray-700);
  margin-bottom: 0.25rem;
}

/* Services et prix */
.services-grid,
.price-grid {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.service-checkbox,
.price-checkbox {
  display: flex;
  align-items: center;
  padding: 0.375rem;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: background-color 0.2s;
}

.service-checkbox:hover,
.price-checkbox:hover {
  background-color: var(--gray-50);
}

.service-checkbox input[type="checkbox"],
.price-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 1rem;
  height: 1rem;
  border: 2px solid var(--primary);
  border-radius: 0.25rem;
  margin-right: 0.75rem;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
}

.service-checkbox input[type="checkbox"]:checked,
.price-checkbox input[type="checkbox"]:checked {
  background-color: var(--primary);
}

.service-checkbox input[type="checkbox"]:checked::after,
.price-checkbox input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 4px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.count {
  margin-left: auto;
  font-size: 0.75rem;
  color: var(--gray-500);
  background: var(--gray-100);
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  min-width: 1.5rem;
  text-align: center;
}

/* Rating */
.rating-filter {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.rating-option {
  display: flex;
  align-items: center;
  padding: 0.375rem;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: background-color 0.2s;
}

.rating-option:hover {
  background-color: var(--gray-50);
}

.rating-radio {
  appearance: none;
  -webkit-appearance: none;
  width: 1rem;
  height: 1rem;
  border: 2px solid var(--primary);
  border-radius: 50%;
  margin-right: 0.75rem;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
}

.rating-radio:checked {
  border-color: var(--primary);
}

.rating-radio:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--primary);
  border-radius: 50%;
}

.rating-content {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.stars {
  display: flex;
  gap: 0.125rem;
}

.star {
  color: var(--gray-300);
}

.star.filled {
  color: var(--primary);
}

.rating-text {
  font-size: 0.875rem;
  color: var(--gray-700);
}

/* Slider de distance */
.radius-slider {
  padding: 0.5rem 0;
  margin-top: -0.25rem;
}

.radius-slider input[type="range"] {
  width: 100%;
  height: 6px;
  -webkit-appearance: none;
  appearance: none;
  background: var(--gray-200);
  border-radius: 3px;
  outline: none;
  margin-bottom: 0.5rem;
}

.radius-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background: var(--primary);
  border: 2px solid white;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.radius-slider input[type="range"]::-webkit-slider-thumb:hover {
  transform: scale(1.1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}

.radius-slider input[type="range"]:focus {
  outline: none;
}

.radius-value {
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--gray-700);
  margin-top: 0.25rem;
}