/* src/components/booking/ServicesSelection.css */
.services-selection {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 80px);
    padding-bottom: 80px; /* Espace pour la barre de confirmation */
    background-color: var(--gray-50);
  }
  
  .services-list {
    flex: 1;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
  }
  
  .service-category {
    margin-bottom: 2rem;
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .service-category h2 {
    color: var(--gray-900);
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid var(--primary-light);
  }
  
  .service-item {
    margin-bottom: 1rem;
    border-radius: 8px;
    transition: all 0.3s ease;
    background: var(--gray-50);
  }
  
  .service-content {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .service-info h3 {
    font-size: 1.1rem;
    color: var(--gray-900);
    margin-bottom: 0.25rem;
  }
  
  .service-info p {
    color: var(--gray-600);
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
  
  .service-details {
    display: flex;
    gap: 1rem;
    font-size: 0.9rem;
  }
  
  .price {
    font-weight: 600;
    color: var(--primary);
  }
  
  .duration {
    color: var(--gray-600);
  }
  
  .select-button {
    background-color: var(--primary);
    color: white;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .select-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(189, 25, 59, 0.2);
  }
  
  .select-button.selected {
    background-color: var(--gray-400);
    cursor: not-allowed;
  }
  
  .confirmation-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }
  
  .total-summary {
    display: flex;
    flex-direction: column;
  }
  
  .total-amount {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--gray-900);
  }
  
  .total-duration {
    font-size: 0.9rem;
    color: var(--gray-600);
  }
  
  .next-step-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: var(--primary);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 25px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .next-step-button:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(189, 25, 59, 0.2);
  }
  
  .next-step-button:disabled {
    background-color: var(--gray-400);
    cursor: not-allowed;
  }
  
  @media (max-width: 768px) {
    .services-list {
      padding: 1rem;
    }
  
    .service-category {
      padding: 1rem;
    }
  
    .confirmation-bar {
      padding: 1rem;
      flex-direction: column;
      gap: 1rem;
    }
  
    .total-summary {
      width: 100%;
      text-align: center;
    }
  
    .next-step-button {
      width: 100%;
      justify-content: center;
    }
  }

  /* Ajoutez ces styles à ServicesSelection.css */
.selected-services-container {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .selected-services-container h2 {
    color: var(--primary);
    margin-bottom: 1.5rem;
  }
  
  .selected-service-item {
    background: var(--gray-50);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .selected-service-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .service-main-info {
    flex: 1;
  }
  
  .service-name {
    font-weight: 500;
    color: var(--gray-900);
    display: block;
    margin-bottom: 0.25rem;
  }
  
  .service-details {
    display: flex;
    gap: 1rem;
    font-size: 0.9rem;
    color: var(--gray-600);
  }
  
  .services-total {
    margin-top: 1.5rem;
    padding-top: 1rem;
    border-top: 1px solid var(--gray-200);
  }
  
  .total-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: var(--gray-700);
  }
  
  .total-line:last-child {
    margin-bottom: 0;
    font-weight: 600;
    color: var(--gray-900);
  }
  
  .total-price {
    color: var(--primary);
    font-weight: 600;
  }
  
  .confirmation-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    gap: 1rem;
  }
  
  .add-more-button {
    padding: 0.75rem 1.5rem;
    background: white;
    border: 2px solid var(--primary);
    color: var(--primary);
    border-radius: 25px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .add-more-button:hover {
    background: var(--primary-light);
  }
  
  .next-step-button {
    padding: 0.75rem 1.5rem;
    background: var(--primary);
    color: white;
    border: none;
    border-radius: 25px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .next-step-button:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(189, 25, 59, 0.2);
  }
  
  .next-step-button:disabled {
    background-color: var(--gray-400);
    cursor: not-allowed;
  }
  
  @media (max-width: 768px) {
    .confirmation-bar {
      padding: 1rem;
      flex-direction: column;
    }
  
    .add-more-button,
    .next-step-button {
      width: 100%;
      justify-content: center;
    }
  }