/* Navigation Menu */
.lateral-nav {
    position: fixed;
    left: 0;
    top: 100px; /* Modification de la position */
    z-index: 100;
}

.lateral-nav-trigger {
    position: absolute;
    left: 0;
    width: 20px;
    height: 40px;
    background: #bd193b;
    border-radius: 0 40px 40px 0;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lateral-nav-trigger::after {
    content: '→';
    color: white;
    font-size: 20px;
}

.lateral-nav:hover .lateral-nav-trigger::after {
    content: '←'; /* Flèche vers la gauche quand ouvert */
    opacity: 1;
}

.lateral-nav-menu {
    position: absolute;
    left: -300px;
    width: 300px;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    border-radius: 0 0 20px 0;
    padding: 20px;
    transition: all 0.3s ease;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.lateral-nav:hover .lateral-nav-menu {
    left: 0;
}

.lateral-nav:hover .lateral-nav-trigger {
    left: 300px;
}

.lateral-nav-menu-item {
    padding: 12px 15px;
    margin: 5px 0;
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.2s ease;
    font-size: 14px;
    color: #333;
    font-weight: 500;
}

.lateral-nav-menu-item:hover {
    background: rgba(189, 25, 59, 0.1);
    color: #bd193b;
}

.lateral-nav-menu-item.active {
    background: #bd193b;
    color: white;
}


/* Supprimer ou commenter les anciennes animations du background et ajouter ces nouvelles animations */
@keyframes rotateAndMove {
    0% {
        transform: translate(0, 0) rotate(0deg) scale(1);
        opacity: 0;
    }
    20% {
        opacity: 0.5; /* Augmenté de 0.3 à 0.5 */
    }
    80% {
        opacity: 0.5; /* Augmenté de 0.3 à 0.5 */
    }
    100% {
        transform: translate(var(--translate-x, 100px), var(--translate-y, 100px)) 
                  rotate(var(--final-rotation, 360deg)) 
                  scale(var(--final-scale, 0.5));
        opacity: 0;
    }
}

.geometric-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 0;
}

.geometric-shape {
    position: absolute;
    top: var(--position-y);
    left: var(--position-x);
    width: var(--size);
    height: var(--size);
    opacity: 0;
    animation: rotateAndMove 20s infinite linear;
    animation-delay: var(--delay);
}

.geometric-shape::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(var(--rotation));
    border: 2px solid rgba(189, 25, 59, 0.2); /* Augmenté de 0.1 à 0.2 */
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    box-shadow: 0 0 10px rgba(189, 25, 59, 0.1); /* Ajout d'une ombre */
}

.geometric-shape:nth-child(3n)::before {
    border-radius: 50%;
    border: 2px solid rgba(51, 51, 51, 0.2); /* Augmenté de 0.1 à 0.2 */
    box-shadow: 0 0 15px rgba(51, 51, 51, 0.1); /* Ajout d'une ombre */
}

.geometric-shape:nth-child(3n + 1)::before {
    border-radius: 20% 80% 20% 80% / 80% 20% 80% 20%;
    border: 2px solid rgba(189, 25, 59, 0.25); /* Augmenté à 0.25 */
    box-shadow: 0 0 12px rgba(189, 25, 59, 0.15); /* Ajout d'une ombre */
}

.geometric-shape:nth-child(3n + 2)::before {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    background: linear-gradient(45deg, rgba(189, 25, 59, 0.15), rgba(248, 244, 240, 0.15)); /* Augmenté de 0.05 à 0.15 */
    box-shadow: 0 0 20px rgba(189, 25, 59, 0.1); /* Ajout d'une ombre */
}

/* Ajuster les styles du slider pour intégrer les animations */
.features-slider {
    position: relative;
    overflow: hidden;
    background: linear-gradient(
        45deg,
        rgba(248, 244, 240, 0.97),
        rgba(248, 244, 240, 0.95)
    );
}

/* Modifier le style des cartes pour qu'elles soient plus transparentes */
.feature-card {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Ajuster l'overlay pour un meilleur effet */
.features-slider::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
        circle at 50% 50%,
        rgba(248, 244, 240, 0.4) 0%,
        rgba(248, 244, 240, 0.8) 100%
    );
    z-index: 1;
    pointer-events: none;
}

/* S'assurer que le contenu reste au-dessus des animations */
.features-content,
.feature-card,
.last-slide-content {
    position: relative;
    z-index: 2;
}

/* Animation des particules */
@keyframes floatUpRight {
    0% {
        transform: translate(0, 100vh) rotate(0deg);
        opacity: 0;
    }
    20% {
        opacity: 0.5;
    }
    80% {
        opacity: 0.5;
    }
    100% {
        transform: translate(20vw, -100px) rotate(360deg);
        opacity: 0;
    }
}

@keyframes floatUpLeft {
    0% {
        transform: translate(0, 100vh) rotate(0deg);
        opacity: 0;
    }
    20% {
        opacity: 0.5;
    }
    80% {
        opacity: 0.5;
    }
    100% {
        transform: translate(-20vw, -100px) rotate(-360deg);
        opacity: 0;
    }
}

.floating-particles {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;
    overflow: hidden;
}

/* Augmenter le contraste des particules */
.particle {
    background: linear-gradient(135deg, rgba(189, 25, 59, 0.3), rgba(248, 244, 240, 0.2)); /* Augmenté le contraste */
    box-shadow: 0 0 15px rgba(189, 25, 59, 0.2); /* Ajout d'une ombre */
}

.particle::before {
    background: linear-gradient(135deg, rgba(189, 25, 59, 0.4), rgba(248, 244, 240, 0.3)); /* Augmenté le contraste */
}


/* Faire flotter certaines particules vers la gauche */
.particle:nth-child(even) {
    animation-name: floatUpLeft;
    animation-duration: 20s;
    width: 30px;
    height: 30px;
}

/* Varier la taille de certaines particules */
.particle:nth-child(3n) {
    width: 25px;
    height: 25px;
    animation-duration: 18s;
}

.particle:nth-child(5n) {
    width: 35px;
    height: 35px;
    animation-duration: 25s;
}

/* Ajustement pour que les particules apparaissent derrière le contenu */
.last-slide-content {
    position: relative;
    z-index: 2;
}

/* Modification de l'overlay existant pour une meilleure visibilité */
.features-slider.with-background::after {
    background-color: rgba(248, 244, 240, 0.75); /* Légèrement plus opaque */
}

/* Au début du fichier, ajoutez les keyframes */
@keyframes slideInRight {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInLeft {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Styles pour les éléments à animer */
.animate-slide .features-image {
    animation: slideInRight 1s ease forwards;
}

.animate-slide .features-text,
.animate-slide .feature-content {
    animation: slideInLeft 1s ease forwards;
}

.animate-slide .feature-item {
    opacity: 0;
    animation: fadeIn 0.5s ease forwards;
}

/* Animation décalée pour chaque feature-item */
.animate-slide .feature-item:nth-child(1) { animation-delay: 0.2s; }
.animate-slide .feature-item:nth-child(2) { animation-delay: 0.4s; }
.animate-slide .feature-item:nth-child(3) { animation-delay: 0.6s; }
.animate-slide .feature-item:nth-child(4) { animation-delay: 0.8s; }

/* État initial des éléments avant l'animation */
.features-image,
.features-text,
.feature-content,
.feature-item {
    opacity: 0;
}

/* Pour le dernier slide avec le background */
.animate-slide.with-background .last-slide-content {
    animation: fadeIn 1s ease forwards;
}

.features-slider-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.features-slider {
    min-height: 100vh;
    height: auto;
    background-color: #F8F4F0;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 3rem;  /* Ajout d'un padding horizontal */
}

.features-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 1.5rem;
}

.features-image {
    width: 65%;
    border-radius: 2rem;
    overflow: hidden;
    height: 500px; /* Hauteur fixe */
    margin: auto;
}

.features-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
    
.feature-card {
    background: white;
    border-radius: 2rem;
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
}

.feature-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.feature-title {
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    font-weight: 700;
    color: #333;
    line-height: 1.2;
    margin-bottom: 2rem;
}
.feature-star {
    color: #bd193b;
    font-size: 2rem;
}

.features-image img {
width: 100%;
height: 100%;
object-fit: cover;
}

.features-text {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
gap: 2rem;
}

.features-subtitle {
font-size: 1.25rem;
color: #666;
margin-top: 1rem; /* Ajout de cette ligne */
}

.features-buttons {
display: flex;
gap: 1rem;
width: 100%;
max-width: 600px; /* Pour limiter la largeur maximale */
}

.features-button {
    flex: 1;
    padding: 1rem 2rem;
    border-radius: 9999px;
    font-weight: 500;
    text-align: center;
    transition: all 0.3s ease;
    white-space: nowrap;
    width: 250px; /* Remplace min-width par width fixe */
    max-width: 250px; /* Ajoute une largeur maximale égale */
}

.features-button.white {
background-color: white;
color: #333;
}

.features-button.dark {
background-color: #333;
color: white;
}

.features-button.red {
    background-color: #bd193b;
    color: white;
}

.btn-feature-disabled {
    background-color: #bcbcbc;
    color: #666;
    cursor: not-allowed;
}

.feature-list {
display: flex;
flex-direction: column;
gap: 1.5rem;
}

.feature-item {
display: flex;
gap: 1rem;
align-items: flex-start;
}

.feature-item-content h3 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #333;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.feature-item-content p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #666;
    line-height: 1.5;
}

/* Ajouter les nouveaux styles */
.features-slider.with-background {
    position: relative;
}

.features-slider.with-background::before {
    content: '';
    position: absolute; /* Changer fixed en absolute */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../assets/fonctionnalités/image_fond.png');
    background-size: cover;
    background-position: center;
    opacity: 0.9;
    z-index: 0; /* Ajouter ceci */
}

.features-slider.with-background::after {
    content: '';
    position: absolute; /* Changer fixed en absolute */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(248, 244, 240, 0.6);
    z-index: 1; /* Ajouter ceci */
}

.last-slide-content {
    position: relative;
    z-index: 2;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 1rem;
}

.last-slide-content .features-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    max-width: 800px;
}

.last-slide-content .features-title {
    text-align: center;
    margin-bottom: 2rem;
}

.last-slide-content .features-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    max-width: 600px;
}

.last-slide-content .features-button {
    flex: 1;
    max-width: 250px;
}

/* Media queries pour le responsive */
@media (max-width: 768px) {
    .features-slider {
        padding: 0 2rem;  /* Padding plus petit sur mobile */
    }

    .features-content, .feature-card {
        grid-template-columns: 1fr;
        gap: 1.5rem;
        padding: 1rem;
    }
    
    .features-title {
        font-size: 1.8rem;
    }

    .features-image {
        order: 2;
        height: 350px;
    }

    .features-text,
    .feature-content {
        order: 1;
    }

    .feature-title {
        font-size: 28px;
    }

    .feature-item {
        gap: 0.75rem;
    }

    .feature-item-content h3 {
        font-size: 13px;
    }

    .feature-item-content p {
        font-size: 13px;
    }

    .feature-star {
        font-size: 18px;
    }

    .feature-item-content p span.highlight {
        color: #bd193b;
    }

    .features-button {
        min-width: 140px;
        padding: 0.75rem 1.5rem;
        font-size: 0.9rem;
    }
}

/* Ajouter ces ajustements dans votre section Media Queries */
@media (max-width: 768px) {
    /* Ajustements existants... */

    /* Menu latéral en mobile */
    .lateral-nav {
        top: 70px;
    }

    .lateral-nav-trigger {
        width: 15px;
        height: 30px;
    }

    .lateral-nav-trigger::after {
        font-size: 16px;
    }

    .lateral-nav-menu {
        width: 250px;
        left: -250px;
        padding: 15px;
    }

    .lateral-nav:hover .lateral-nav-trigger {
        left: 250px;
    }

    .lateral-nav-menu-item {
        padding: 8px 12px;
        font-size: 12px;
    }

    /* Premier slide responsive */
    .features-slider .features-content {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .features-slider .features-image {
        width: 100%;
        height: 250px;
        order: 1;
    }

    .features-slider .features-text {
        order: 2;
        width: 100%;
        text-align: center;
    }

    .features-slider .features-title {
        font-size: 24px;
        line-height: 1.3;
        margin-bottom: 1rem;
    }

    .features-slider .features-subtitle {
        font-size: 1rem;
    }

    .features-buttons {
        flex-direction: column;
        align-items: center;
        gap: 0.75rem;
    }

    .features-button {
        width: 100%;
        max-width: 280px;
        min-width: unset;
        padding: 0.75rem 1.5rem;
        font-size: 0.9rem;
    }
}

/* Ajoutez également une media query pour les très petits écrans */
@media (max-width: 480px) {
    .lateral-nav-menu {
        width: 200px;
        left: -200px;
    }

    .lateral-nav:hover .lateral-nav-trigger {
        left: 200px;
    }

    .features-slider .features-title {
        font-size: 20px;
    }

    .features-slider {
        padding: 0 1rem;
    }

    .features-slider .features-image {
        height: 200px;
    }
}

/* Pour gérer l'orientation paysage sur mobile */
@media (max-height: 480px) and (orientation: landscape) {
    .features-slider {
        min-height: unset;
        padding: 2rem 1rem;
    }

    .features-slider .features-image {
        height: 180px;
    }

    .features-slider .features-content {
        gap: 1rem;
    }
}