/* Structure principale */
.messaging {
  @apply relative flex inset-0 bg-gradient-to-r from-rose-50 to-pink-50;
  height: 80vh;
  margin-top: 10px;
}

.messaging__container {
  @apply flex w-full bg-white shadow-lg overflow-hidden;
  min-height: 0;
  height: 87vh;
}

.messaging__sidebar {
  @apply w-full md:w-96 border-r border-pink-100;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.messaging__main {
  @apply hidden md:flex w-full flex-col bg-white/50;
  height: 100%;
  position: relative; /* Ajout */
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0; /* Espace pour le champ de saisie */
}

/* Nouvelle classe pour le conteneur de saisie */
.flex-shrink-0.p-4.border-t {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 10;
}

/* Style global de la scrollbar */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #e4e4e7 transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #e4e4e7;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #d4d4d8;
}

/* Style pour la zone de saisie */
.flex-shrink-0.p-4.border-t {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 10;
  padding: 0.75rem;
}


.messages-container > div:not(:last-child) {
  margin-bottom: 0;
}

/* Conversation item styles */
.conversation-item {
  @apply border-b border-gray-100 relative overflow-hidden;
  padding: 8px 12px;
  height: 64px;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 241, 242, 0) 100%
  );
}

.conversation-item > div {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
}

.conversation-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    rgba(244, 114, 182, 0.05) 0%,
    rgba(251, 207, 232, 0.1) 50%,
    rgba(244, 114, 182, 0.05) 100%
  );
  transform: translateX(-100%);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 0;
}

.conversation-item:hover::before {
  transform: translateX(0);
}

.conversation-item.active {
  background: linear-gradient(
    120deg,
    rgba(244, 114, 182, 0.1) 0%,
    rgba(251, 207, 232, 0.2) 50%,
    rgba(244, 114, 182, 0.1) 100%
  );
}

/* Conversation list */
.conversation-list {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.conversation-list + div {
  flex-shrink: 0;
}

/* UI Elements */
.avatar-circle {
  width: 36px;
  height: 36px;
  min-width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
}

.content-wrapper {
  flex: 1;
  min-width: 0;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.conversation-name {
  font-weight: 500;
  font-size: 0.875rem;
  color: #1f2937;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 160px;
  transition: transform 0.3s ease;
}

.conversation-time {
  font-size: 0.75rem;
  color: #6b7280;
  flex-shrink: 0;
  margin-left: 8px;
}

.message-preview {
  font-size: 0.813rem;
  color: #6b7280;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

/* Button styles */
.delete-button {
  @apply bg-white/80 backdrop-blur-sm rounded-full;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateX(10px);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  margin-left: auto;
}

.conversation-item:hover .delete-button {
  opacity: 1;
  transform: translateX(0);
}

.new-conversation-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem;
  background: linear-gradient(to right, #ec4899, #f43f5e);
  color: white;
  border-radius: 9999px;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.new-conversation-btn:hover {
  background: linear-gradient(to right, #d946ef, #e11d48);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Status indicators */
.online-status {
  @apply bg-green-500;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
}

.online-status::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 50%;
  background: rgba(34, 197, 94, 0.4);
  animation: pulse 2s ease-in-out infinite;
}

.unread-conversation-indicator {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 12px;
  height: 12px;
  background-color: #3b82f6;
  border: 2px solid white;
  border-radius: 50%;
  animation: pulse 2s infinite;
}

/* Animation */
@keyframes pulse {
  0% { transform: scale(1); opacity: 0.5; }
  50% { transform: scale(1.5); opacity: 0; }
  100% { transform: scale(1); opacity: 0.5; }
}

.search-input {
  padding-left: 2rem;  /* Espace pour l'icône */
}

.search-icon {
  pointer-events: none;  /* Pour éviter les interactions avec l'icône */
}

.sweet-popup {
  font-family: inherit;
  border-radius: 1rem;
}

.sweet-confirm-button {
  border-radius: 0.5rem !important;
  padding: 0.5rem 1.5rem !important;
}

/* Responsive styles */
@media screen and (max-width: 768px) {

  .messages-container {
    margin-bottom: 0;
  }

  .flex-shrink-0.p-4.border-t {
    padding: 5px;
  }

  .messaging {
    padding: 0;
  }

  .messaging__sidebar {
    width: 100%;
  }

  .messaging__main {
    display: none;
  }

  .messaging__container.conversation-open .messaging__sidebar {
    display: none;
  }

  .messaging__container.conversation-open .messaging__main {
    display: flex;
  }

  [class*="max-w"] p {
    margin-bottom: 2px;
  }

  .flex.items-center.gap-2 {
    gap: 0.25rem;
  }

  .p-2 { padding: 0.25rem; }
  .p-3 { padding: 0.375rem; }

  .messaging .lucide {
    width: 18px;
    height: 18px;
  }

  div[class*="min-w-"] {
    min-width: 32px !important;
    width: 2rem !important;
    height: 2rem !important;
  }
  
  .conversation-name {
    max-width: 140px;
  }

  /* Header plus compact */
  .flex.items-center.gap-4.p-4.border-b {
    padding: 5px;
    gap: 0.25rem;
  }

  /* Avatar plus petit (utiliser un sélecteur plus générique) */
  .messaging div[class*="rounded-full"] {
    min-width: 28px !important;
    width: 1.75rem !important;
    height: 1.75rem !important;
  }

  /* Réduire la taille du nom */
  h2.font-medium {
    font-size: 0.875rem;
  }

  /* Status en ligne plus petit */
  .text-sm.text-green-500 {
    font-size: 0.75rem;
  }

  /* Messages plus compacts */
  [class*="max-w"] {
    padding: 0.375rem 0.5rem;
    font-size: 0.8125rem;
  }

  [class*="max-w"] p {
    margin-bottom: 1px;
  }

  /* Heure des messages plus petite */
  .text-xs {
    font-size: 0.625rem;
  }

  /* Zone de saisie plus compacte */
  .flex-shrink-0.p-4.border-t {
    padding: 0.375rem 0.5rem;
  }

  .flex.items-center.gap-2 {
    gap: 0.25rem;
  }

  /* Champ de saisie plus compact */
  input[type="text"] {
    padding: 0.25rem 0.5rem;
    font-size: 0.8125rem;
    min-height: 32px;
  }

  /* Boutons plus petits */
  .p-2, .p-3 {
    padding: 0.25rem;
  }

  /* Icônes plus petites */
  .messaging .lucide {
    width: 16px;
    height: 16px;
  }
  /* Ajuster la hauteur et les marges des éléments de conversation */
  .conversation-item {
    height: 48px; /* Réduire la hauteur en mobile */
    padding: 4px 8px; /* Réduire le padding */
  }

  /* Ajuster les espacements internes */
  .conversation-item > div {
    gap: 2px;
  }

  .relative.flex.items-center.gap-3.mt-4.mb-4 {
    margin-top: 0;
    margin-bottom: 0;
  }

  /* Ajuster la hauteur du contenu */
  .flex-1.min-w-0.mt-6 {
    margin-top: 0;
  }

  /* Réduire l'espacement des éléments intérieurs */
  .flex.justify-between.items-start {
    gap: 0.5rem;
  }

  /* Ajuster la police pour la preview du message */
  .text-sm.text-gray-500.truncate {
    font-size: 0.75rem;
    line-height: 1;
  }
  .search-input {
    font-size: 13px;
    padding: 0.375rem 0.5rem 0.375rem 1.75rem;  /* top right bottom left */
}

.search-icon {
    width: 14px;
    height: 14px;
    left: 0.5rem;
}

/* Changer le placeholder en responsive */
.search-input::placeholder {
    font-size: 13px;
}

}