:root {
  --primary: #bd193b;
  --primary-light: rgba(189, 25, 59, 0.1);
  --gray-50: #f8f9fa;
  --gray-100: #f1f3f5;
  --gray-200: #e9ecef;
  --gray-600: #868e96;
  --gray-700: #495057;
  --gray-900: #212529;
}

.services-title {
  font-size: 1.5rem;
  color: var(--gray-900);
  margin-bottom: 1.5rem;
  font-weight: 600;
}

/* Styles de base */
.services-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  animation: fadeIn 0.5s ease-out;
}

.service-category {
  margin-bottom: 2rem;
}

.service-category h2 {
  font-size: 1.25rem;
  color: var(--gray-900);
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid var(--primary-light);
  font-weight: 600;
}

.service-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.service-item-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service-item {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 1.25rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 25px rgba(189, 25, 59, 0.1);
  transition: all 0.3s ease;
}

.service-info {
  flex: 1;
}

.service-name {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--gray-900);
  margin-bottom: 0.25rem;
}

.service-description {
  font-size: 0.9rem;
  color: var(--gray-600);
}

.service-details {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.price-duration {
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
}

.price {
  display: block;
  font-weight: 600;
  color: var(--gray-900);
  font-size: 1.1rem;
}

.duration {
  display: block;
  font-size: 0.9rem;
  color: var(--gray-600);
}

/* Styles du bouton d'ajout */
.add-button {
  width: 40px;
  height: 40px;
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 15px rgba(189, 25, 59, 0.3);
  padding: 0;
}

.add-button:not(:disabled):hover {
  background-color: #a31533;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(189, 25, 59, 0.4);
}

.add-button:not(:disabled):active {
  transform: translateY(0);
  box-shadow: 0 2px 10px rgba(189, 25, 59, 0.3);
}

.add-button svg {
  width: 20px;
  height: 20px;
  color: white;
}

/* Styles pour les états désactivés */
.add-button:disabled,
.add-button.selected {
  background-color: var(--gray-600);
  cursor: not-allowed;
  opacity: 0.5;
  box-shadow: none;
  transform: none;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Media Queries */
@media (max-width: 1200px) {
  .services-container { padding: 1.5rem; }
}

@media (max-width: 992px) {
  .services-container { padding: 1.25rem; }
}

@media (max-width: 768px) {
  .services-container { padding: 1rem; }
  .service-item { padding: 1rem; }
  .service-name, .price { font-size: 1rem; }
}

@media (max-width: 480px) {
  .services-container { padding: 0.75rem; }
  .service-details { gap: 1rem; }
  .service-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  .service-details {
    /*width: 100%;*/
    justify-content: space-between;
  }
}