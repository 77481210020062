.bande-section {
    position: relative;
    height: 400px;
    width: 100%;
    margin-top: 0;
    overflow: hidden;
  }
  
  .bande-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .bande-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.2)
    );
  }
  
  .bande-content {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
  }
  
  .bande-content h1 {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .bande-content p {
    font-size: 1.5rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }
  
  .content-section {
    padding: 5rem 0;
  }
  
  .content-section img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 1rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .content-section img:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
  }
  
  .content-section h2 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  .content-section p {
    color: #666;
    line-height: 1.8;
    margin-bottom: 1rem;
  }
  
  .bg-light-section {
    background-color: #f8f9fa;
  }
  
  .cta-section {
    padding: 5rem 0;
    text-align: center;
  }
  
  .cta-section h2 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .btn-primary-custom {
    background-color: #bd193b;
    border-color: #bd193b;
    color: white;
    padding: 0.75rem 2rem;
    border-radius: 50px;
    font-weight: 600;
    transition: all 0.3s ease;
  }
  
  .btn-primary-custom:hover {
    background-color: #a31532;
    border-color: #a31532;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(189, 25, 59, 0.2);
  }
  
  .btn-outline-custom {
    border: 2px solid #bd193b;
    color: #bd193b;
    padding: 0.75rem 2rem;
    border-radius: 50px;
    font-weight: 600;
    transition: all 0.3s ease;
  }
  
  .btn-outline-custom:hover {
    background-color: #bd193b;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(189, 25, 59, 0.2);
  }
  
  @media (max-width: 768px) {
    .bande-section {
      height: 300px;
      margin-top: 70px;
    }
  
    .bande-content h1 {
      font-size: 2.5rem;
    }
  
    .bande-content p {
      font-size: 1.25rem;
    }
  
    .content-section {
      padding: 3rem 0;
    }
  
    .content-section h2 {
      font-size: 2rem;
      margin-top: 1.5rem;
    }
  }

.btn-tile {
  @apply  p-8 flex flex-col items-center justify-center gap-6 no-underline text-gray-800 font-semibold border-2 rounded-2xl shadow-xl
    hover:scale-105 duration-300 size-72;
}
.btn-tile span {
  @apply size-36 rounded-full overflow-hidden flex items-center justify-center;
}
.btn-tile img {
  @apply scale-150;
}