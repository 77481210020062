.packs-hero {
    position: relative;
    width: 100%;
    height: calc(80vh - 50px); /* Abaissement de 50px */
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Changé de flex-start à flex-end */
    padding-right: 5rem; /* Ajout d'une marge à droite */
    margin-top: 10px; /* Ajoute l'espace en haut */
    background: transparent; /* Supprime le gradient */
    z-index: 999;
}

.packs-hero-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 1; /* Image à 100% d'opacité */
}

.packs-hero-content {
    padding: 2rem;
    max-width: 500px; /* Réduit légèrement la largeur maximale */
    margin-right: 2rem; /* Ajoute une marge à droite */
    border-radius: 10px; /* Ajoute un peu d'arrondi */
}

.packs-hero h1 {
    font-size: 3rem; /* Diminution de la taille de police */
    font-weight: 600;
    color: #333;
    line-height: 1.2;
    margin: 0;
}

.prices-section {
    background-color: #eee0d6;
    padding: 4rem 2rem;
    margin-top: -2rem;
}

.prices-container {
    max-width: 1200px;
    margin: 0 auto;
}

.prices-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    color: #000;
    font-weight: 600;
}

.price-cards {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.price-card {
    background: white;
    border-radius: 12px;
    padding: 2rem;
    width: 300px;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.browser-dots {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem; /* Réduit l'espace sous les points */
    padding-bottom: 1rem; /* Ajoute de l'espace pour la ligne */
    border-bottom: 1px solid #e0e0e0; /* Ajoute la ligne noire en bas */
}

.browser-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.dot-red { background-color: #FF5F56; }
.dot-yellow { background-color: #FFBD2E; }
.dot-green { background-color: #27C93F; }

.price-card-title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: #000;
    margin: 1rem 0;
    padding: 0 1rem;
}

.price-value {
    text-align: center;
    width: fit-content;
    margin: 1rem auto;
    padding: 0.5rem 1.5rem;
    background: #333;
    color: white;
    border-radius: 6px;
    font-weight: 600;
}

.pack-price-list {
    list-style: none;
    padding: 0;
}

.pack-price-item {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    margin-bottom: 1rem;
    font-size: 0.9rem;
}

.pack-price-icon {
    min-width: 20px;
}

.bestseller-badge {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #333;
    color: white;
    padding: 0.4rem 0.8rem;
    transform: rotate(45deg); /* Ajuste la rotation */
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.pack-price-icon svg {
    margin-top: 2px;
}

.packs-content-wrapper {
    background-color: #eee0d6;
    width: 100%;
    min-height: calc(100vh - 80vh + 50px); /* Calcule la hauteur restante après le hero */
    margin-top: -2rem; /* Garde le chevauchement avec le hero */
    padding-bottom: 4rem; /* Ajoute de l'espace en bas */
}

.boost-button-container {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: #eee0d6;
}

.boost-button {
    background-color: white;
    color: black;
    border: none;
    border-radius: 25px;
    padding: 1rem 3rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.boost-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.pack-info-sections {
    padding: 4rem 2rem;
    background-color: #eee0d6;
}

.pack-info-section {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 4rem auto;
    gap: 4rem;
    position: relative;
}

.pack-info-section:nth-child(even) {
    flex-direction: row-reverse;
}

.pack-info-image-container {
    flex: 0 0 40%;
    position: relative;
}

.pack-info-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 8px;
}

.pack-info-content {
    flex: 0 0 60%;
    padding: 2rem;
    position: relative;
}

.pack-info-title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    padding: 0.8rem 1.5rem;
    display: inline-block;
    position: relative;
    z-index: 2;
}

/* Ajoutez une nouvelle classe pour la bande blanche */
.title-background {
    position: absolute;
    height: 40px; /* Hauteur de la bande blanche */
    background-color: white;
    z-index: 1;
}

/* Positionnement spécifique pour chaque section */
.section-1 .title-background {
    top: 6.5rem;
    left: 3rem;
    width: 60%;
}

.section-2 .title-background {
    top: 6.5rem;
    left: 3rem;
    width: 75%;
}

.section-3 .title-background {
    top: 6.5rem;
    left: 3rem;
    width: 40%;
}

.pack-info-text {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
}

.corner-star {
    position: absolute;
    width: 40px;  /* Augmenté de 24px à 40px */
    height: 40px; /* Augmenté de 24px à 40px */
    font-size: 32px; /* Ajouté pour augmenter la taille de l'étoile elle-même */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;  /* Assurez-vous que l'étoile est bien visible */
}

.star-top-left {
    top: -12px;
    left: -12px;
}

.star-top-right {
    top: -12px;
    right: -12px;
}

.star-bottom-right {
    bottom: -12px;
    right: -12px;
}

.pack-info-section {
    padding: 0 2rem;
    position: relative;
}

.vertical-line {
    width: 2px;
    height: 120px;
    background-color: #333;
    position: absolute;
}

.horizontal-line {
    height: 10px;
    background-color: white;
    position: absolute;
}

.section-1 .horizontal-line {
    top: 20%;
    left: 0;
    right: 0;
}

.section-1 .horizontal-line {
    top: 0;
    left: 0;
    right: 0;
}


/* Première section */
.section-1 .line-left {
    left: -5%;
    top: 0;
}

.section-1 .line-right {
    right: -5%;
    top: 40%;
}

/* Deuxième section */
.section-2 .line-left {
    left: -5%;
    top: 80%;
    transform: translateY(-50%);
}

.section-2 .line-middle {
    left: 55%;
    top: 50%;
    transform: translate(-55%, -45%);
}

.section-2 .line-right {
    right: -5%;
    top: 30%;
    transform: translateY(-50%);
}

/* Troisième section */
.section-3 .line-left {
    left: -5%;
    bottom: 80%;
}

.section-3 .line-right {
    right: -5%;
    bottom: 20%;
}

@media (max-width: 768px) {
    .packs-hero {
        height: calc(60vh - 50px);
        padding-right: 1rem;
        justify-content: center;
        margin-top: 50px;
    }

    .packs-hero h1 {
        font-size: 2rem;
    }

    .packs-hero-content {
        margin-right: 0;
        padding: 1.5rem;
    }

    .prices-section {
        padding: 2rem 1rem;
    }
    
    .price-cards {
        gap: 1rem;
    }
    
    .price-card {
        width: 100%;
        max-width: 300px;
    }

    .pack-info-section {
        flex-direction: column;
        gap: 2rem;
    }

    .pack-info-section:nth-child(even) {
        flex-direction: column;
    }

    .pack-info-image-container,
    .pack-info-content {
        flex: 0 0 100%;
    }

    .pack-info-image {
        height: 300px;
    }

    .section-2 .line-middle {
        display: none;
    }
}

/* Ajoutez ce code à la fin de votre fichier PacksCommunication.css */

/* Animation des particules */
.particles-container {
    position: absolute; /* Changé de fixed à absolute */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
  
  .particle {
    position: absolute;
    background: rgba(189, 25, 59, 0.1);
    border-radius: 50%;
    animation: float-particle 15s infinite linear;
  }
  
  .geometric-shape {
    position: absolute;
    opacity: 0.1;
    animation: rotate-shape 20s infinite linear;
  }
  
  .circle-shape {
    width: 100px;
    height: 100px;
    border: 2px solid #333;
    border-radius: 50%;
  }
  
  .square-shape {
    width: 80px;
    height: 80px;
    border: 2px solid #333;
    transform-origin: center;
  }
  
  .triangle-shape {
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 86.6px solid rgba(51, 51, 51, 0.1);
  }
  
  /* Animation de la section des prix */
  .price-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: card-float 6s infinite ease-in-out;
  }
  
  .price-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .bestseller-badge {
    animation: pulse 2s infinite;
  }
  
  /* Animation du background */
  .background-shapes {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
  }
  
  .moving-dot {
    position: absolute;
    width: 4px;
    height: 4px;
    background: #333;
    border-radius: 50%;
    opacity: 0.2;
  }
  
  /* Keyframes pour les animations */
  @keyframes float-particle {
    0% {
      transform: translate(0, 0) rotate(0deg);
    }
    25% {
      transform: translate(100px, 100px) rotate(90deg);
    }
    50% {
      transform: translate(200px, 0) rotate(180deg);
    }
    75% {
      transform: translate(100px, -100px) rotate(270deg);
    }
    100% {
      transform: translate(0, 0) rotate(360deg);
    }
  }
  
  @keyframes rotate-shape {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes card-float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Animation pour le bouton boost */
  .boost-button {
    position: relative;
    overflow: hidden;
  }
  
  .boost-button::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(45deg, transparent, rgba(255,255,255,0.3), transparent);
    transform: rotate(45deg);
    animation: shine 3s infinite;
  }
  
  @keyframes shine {
    0% {
      left: -50%;
    }
    100% {
      left: 150%;
    }
  }
  
  /* Animation des lignes */
  .vertical-line {
    animation: line-grow 1s ease-out;
    transform-origin: top;
  }
  
  .horizontal-line {
    animation: line-extend 1s ease-out;
    transform-origin: left;
  }
  
  @keyframes line-grow {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
  
  @keyframes line-extend {
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  }

  /* Ajoutez ce code à la fin de votre fichier PacksCommunication.css */

/* Animations d'entrée */
.fade-in, .slide-in-right, .slide-in-left, .scale-in {
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform, opacity;
}

.fade-in {
    transform: translateY(30px);
}
  
  .fade-in.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Délais d'animation pour les cartes */
    .price-card:nth-child(1) { transition-delay: 0.1s; }
    .price-card:nth-child(2) { transition-delay: 0.3s; }
    .price-card:nth-child(3) { transition-delay: 0.5s; }
    
  /* Animation du titre hero */
  .slide-in-right {
    transform: translateX(50px);
  }
  
  /* Animation des sections d'info */
  .slide-in-left {
    transform: translateX(-50px);
  }
  
  /* Animation des images */
  .scale-in {
    transform: scale(0.95);
  }
  
  .fade-in.visible,
  .slide-in-right.visible,
  .slide-in-left.visible,
  .scale-in.visible {
      opacity: 1;
      transform: translate(0) scale(1);
  }
  
  /* Animation des particules */
/* Ajoutez ou modifiez ces styles dans votre CSS */

.particles-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
    overflow: hidden;
    background: transparent;
}

.geometric-shape {
    position: absolute;
    opacity: 0.1;
    pointer-events: none;
    transition: transform 0.3s ease;
}

.circle-shape {
    width: 100px;
    height: 100px;
    border: 2px solid #333;
    border-radius: 50%;
}

.square-shape {
    width: 80px;
    height: 80px;
    border: 2px solid #333;
}

.triangle-shape {
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-bottom: 70px solid rgba(51, 51, 51, 0.1);
}

.particle {
    position: absolute;
    background: rgba(189, 25, 59, 0.1);
    border-radius: 50%;
    pointer-events: none;
}

@keyframes float-particle {
    0%, 100% {
        transform: translate(0, 0) rotate(0deg);
    }
    25% {
        transform: translate(100px, -100px) rotate(90deg);
    }
    50% {
        transform: translate(200px, 0) rotate(180deg);
    }
    75% {
        transform: translate(100px, 100px) rotate(270deg);
    }
}

@keyframes rotate-shape {
    0% {
        transform: rotate(0deg) translateY(0);
    }
    50% {
        transform: rotate(180deg) translateY(20px);
    }
    100% {
        transform: rotate(360deg) translateY(0);
    }
}

/* Appliquer les animations */
.particle {
    animation: float-particle 15s infinite linear;
}

.geometric-shape {
    animation: rotate-shape 20s infinite ease-in-out;
}
  
  /* Animation de la section des prix */
  .price-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: card-float 6s infinite ease-in-out;
  }
  
  .price-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .bestseller-badge {
    animation: pulse 2s infinite;
  }
  
  /* Animation du background */
  .background-shapes {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
  }
  
  .moving-dot {
    position: absolute;
    width: 4px;
    height: 4px;
    background: #333;
    border-radius: 50%;
    opacity: 0.2;
  }
  
  /* Keyframes pour les animations */
  @keyframes float-particle {
    0% {
      transform: translate(0, 0) rotate(0deg);
    }
    25% {
      transform: translate(100px, 100px) rotate(90deg);
    }
    50% {
      transform: translate(200px, 0) rotate(180deg);
    }
    75% {
      transform: translate(100px, -100px) rotate(270deg);
    }
    100% {
      transform: translate(0, 0) rotate(360deg);
    }
  }
  
  @keyframes rotate-shape {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes card-float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Animation pour le bouton boost */
  .boost-button {
    position: relative;
    overflow: hidden;
  }
  
  .boost-button::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(45deg, transparent, rgba(255,255,255,0.3), transparent);
    transform: rotate(45deg);
    animation: shine 3s infinite;
  }
  
  @keyframes shine {
    0% {
      left: -50%;
    }
    100% {
      left: 150%;
    }
  }
  
  /* Animation des lignes */
  .vertical-line {
    animation: line-grow 1s ease-out;
    transform-origin: top;
  }
  
  .horizontal-line {
    animation: line-extend 1s ease-out;
    transform-origin: left;
  }
  
  @keyframes line-grow {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
  
  @keyframes line-extend {
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  }

  .main-contents {
    position: relative;
    background-color: #eee0d6;
}