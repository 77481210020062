/* Style de base de la navbar */
.navbar {
  position: relative;
  height: 80px;
  transition: all 0.3s ease;
  padding: 0.5rem 0 !important;
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
  white-space: nowrap;
  margin-left: auto; /* Force l'alignement à droite */
}

/* À ajouter dans Header.css */
.nav-item .nav-link {
  cursor: pointer;
}

nav .cart-icon-container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

nav .cart-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 8px;
  font-size: 12px;
}

/* État par défaut pour toutes les pages : fond blanc et liens noirs */
.navbar {
  background-color: rgba(255, 255, 255, .95);
  backdrop-filter: blur(10px);
}

.navbar .nav-link {
  color: #333 !important;
}

/* Style spécifique pour la home uniquement */
body[data-page="home"] .navbar:not(.scrolled):not(.blured) {
  background-color: transparent !important;
  backdrop-filter: none !important;
}

/* Etat initial de la home */
body[data-page="home"] .navbar {
  background-color: rgba(255, 255, 255, .95);
  backdrop-filter: blur(10px);
}

body[data-page="home"] .navbar .nav-link {
  color: #333 !important;
}

/* Etat non scrollé de la home */
body[data-page="home"] .navbar:not(.scrolled):not(.blured) {
  background-color: transparent !important;
  backdrop-filter: none !important;
}

body[data-page="home"] .navbar:not(.scrolled):not(.blured) .nav-link {
  color: white !important;
}

/* Force le logo rouge par défaut */
.navbar .navbar-logo {
  content: url('../../assets/images/logoRouge.png');
}

/* Change en blanc uniquement quand non scrollé sur la home */
body[data-page="home"] .navbar:not(.scrolled):not(.blured) .navbar-logo {
  content: url('../../assets/images/logoBlanc.png');
}



/* Style spécifique pour le bouton business */
.nav-link .business-button {
  position: relative;
  transition: all 0.3s ease;
  padding: 0.5rem 0 !important;
  font-weight: 500;
  font-size: 1rem;
  margin: 0 0.8rem;
  white-space: nowrap;
}

/* Container principal */
.navbar > .container {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  max-width: 100%; /* Permettre l'expansion complète */
  padding-right: 2rem; /* Marge droite constante */
  padding-left: 2rem; /* Marge gauche constante */
}
/* Logo et marque */
nav .navbar-brand {
  transition: all 0.3s ease;
  padding: 0;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

nav .navbar-logo {
  width: 120px;
  height: 120px;
  transition: all 0.3s ease;
  object-fit: contain;
  margin-top: 0;
}

/* Ajouter cette règle avec une spécificité plus élevée */
.navbar .nav-link.login-button,
.navbar.scrolled .nav-link.login-button,
.navbar.blured .nav-link.login-button,
.navbar.search .nav-link.login-button {
  color: white !important;
}

.navbar .nav-link.login-button:hover,
.navbar.scrolled .nav-link.login-button:hover,
.navbar.blured .nav-link.login-button:hover,
.navbar.search .nav-link.login-button:hover {
  color: #bd193b !important;
}

/* Ajouter cette règle spécifique pour la home en état transparent */
body[data-page="home"] .navbar:not(.scrolled):not(.blured) .nav-link.login-button {
  background-color: #bd193b !important;
  color: white !important;
}

body[data-page="home"] .navbar:not(.scrolled):not(.blured) .nav-link.login-button:hover {
  background-color: white !important;
  color: #bd193b !important;
}

/* Navigation */
nav .navbar-nav {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-left: auto;
  justify-content: flex-end; /* Force l'alignement à droite */
}

nav .nav-link {
  position: relative;
  transition: all 0.3s ease;
  padding: 0 !important;
  font-weight: 500;
  font-size: 1rem;
  margin: 0 30px;
  white-space: nowrap;
}

/* Animation des liens */
nav .nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: #bd193b;
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

nav .nav-link:hover::after {
  width: 80%;
}

/* États de la navbar */
.navbar.blured,
.navbar.search,
.navbar.scrolled {
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, .95);
}

.navbar.blured .nav-link,
.navbar.search .nav-link,
.navbar.scrolled .nav-link {
  color: #333 !important;
}

.navbar.blured .navbar-logo,
.navbar.scrolled .navbar-logo {
  width: 110px;
  height: 110px;
}

/* Modifie l'espace entre le menu et les boutons */
.navbar-nav > li:nth-child(2) {
  margin-right: 100px; /* Espace après "Trouver mon expert" */
}

/* Modifie l'espace entre les boutons et le calendrier */
.navbar-nav > li:nth-last-child(1) {
  margin-left: 50px; /* Espace avant le calendrier */
}

/* Boutons de connexion et inscription */
nav .login-button {
  display: inline-block;
  background-color: #bd193b !important;
  color: white !important;
  padding: 0.6rem 1.5rem !important;
  border-radius: 25px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(189, 25, 59, 0.2);
  margin-left: 1.5rem;
  border: 2px solid transparent;
}

nav .login-button:hover {
  background-color: white !important;
  border: 2px solid #bd193b;
  color: #bd193b !important;
}

nav .login-button::after {
  display: none;
  color: white !important;
}

/* Styles pour la navbar mobile */
@media (max-width: 991px) {
  .navbar {
    height: 70px;
    padding: 0.5rem 1rem;
    backdrop-filter: blur(10px);
    background-color: transparent;
  }

  nav .navbar-nav {
    flex-direction: column;
    margin: 0;
    padding: 1rem 0;
    background-color: white;
    width: 100%;
    text-align: left;
  }

  nav .nav-item {
    width: 100%;
    display: flex;
    justify-content: left;
    padding: 0;
  }

  nav .nav-link {
    text-align: left;
    justify-content: left;
    display: flex;
    width: auto;
    margin: 5px auto;
  }

  /* Centrer spécifiquement les liens du menu principal */
  nav .navbar-nav .nav-item:nth-child(-n+4) .nav-link {
    width: 100%;
    justify-content: center;
    padding: 0 !important;
  }

  nav .navbar-collapse .navbar-nav .nav-link:not(.login-button) {
    color: #333 !important;
  }
  
  nav .navbar-brand {
    padding-left: 0.5rem; /* Ajout du padding à gauche du logo */
  }
  
  nav .navbar-logo {
    width: 62.5px;
    height: 80px;
    margin-top: 0;
  }
  
  nav .scrolled .navbar-logo,
  nav .blured .navbar-logo {
    width: 62.5px;
    height: 80px;
  }

  nav .collapse:not(.show) {
    display: none !important;
  }
  
  nav .navbar-collapse {
    background-color: white;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    color: #333 !important; /* Force la couleur noire pour les liens */
  }

  body[data-page="home"] .navbar:not(.scrolled):not(.blured) .navbar-collapse .nav-link:not(.login-button):not(.signup-button) {
    color: #333 !important;
  }
  
  nav .collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  
  
  nav .navbar-nav .nav-item {
    width: 100%;
    text-align: center;
  }


  /* Réinitialiser la marge en mobile */
  nav .navbar-nav > li:nth-child(2) {
    margin-right: 0;
  }

  
  /* Assurez-vous que les boutons ont la même largeur */
  nav .login-button,
  nav .signup-button {
    width: 80%;
    margin: 0.5rem auto;
  }

  /* Style pour le bouton hamburger */
  nav .navbar-toggler {
    padding: 0.25rem 0.75rem;
    border: none;
    background-color: transparent;
  }

  nav .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
  }

  .navbar-nav .nav-item:last-child,
  .navbar-nav .nav-item:nth-last-child(2) {
    display: inline-flex;
    justify-content: center;
    width: auto;
    margin: 0 10px;
  }

  .business-dropdown-container {
    width: 100%;
  }
  
  .business-dropdown-menu {
    width: 100%;
    margin: 0;
    box-shadow: none;
  }




  

  .navbar-logo {
    width: 90px;
    height: 90px;
  }

  .navbar-toggler {
    border: none;
    padding: 0.25rem 0.75rem;
    margin-right: 1rem;
  }

  .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
  }

  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 1rem 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav-link {
    padding: 0.8rem 1.5rem !important;
    margin: 0;
    width: 100%;
    text-align: center;
  }

  /* Style uniforme pour les boutons en mobile */
  nav .login-button,
  .signup-button,
  .signup-dropdown-container {
    margin: 0.5rem auto;
    width: 90% !important; /* Force la même largeur */
    min-width: 375px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  nav .login-button {
    margin: 0.5rem auto;
    padding: 0.6rem 0 !important; /* Ajustement du padding horizontal */
  }

  .signup-button {
    padding: 0.6rem 0 !important; /* Ajustement du padding horizontal */
  }

  .signup-dropdown-container {
    padding: 0;
  }

  .login-button,
  .signup-button {
    margin: 0.5rem auto;
    width: 80%;
    text-align: center;
  }

  .signup-dropdown-container {
    width: 100%;
  }

  .signup-dropdown-menu {
    position: static;
    width: 100%;
    box-shadow: none;
    margin: 0;
    background-color: rgba(189, 25, 59, 0.05);
    border-radius: 0;
  }

  .nav-link .business-button {
    margin: 0 50px;
    white-space: nowrap;
  }

  /* Les deux derniers éléments dans une ligne flex */
  .navbar-nav > li:nth-last-child(-n+2) {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;  /* Espace entre les deux éléments */
    padding: 10px 0;
  }

  /* Ajustement pour l'alignement */
  .cart-icon-container {
    margin: 0;
    padding: 0;
  }
}



/* Breakpoint pour très petits écrans */
@media (max-width: 360px) {
  .navbar {
    height: 60px;
  }
  
  .navbar-brand {
    padding-left: 0.75rem; /* Padding légèrement plus grand pour les très petits écrans */
  }
  
  .navbar-logo {
    width: 55px;
    height: 80px;
  }
  
  .scrolled .navbar-logo {
    width: 55px;
    height: 80px;
  }
}

/* Style pour le fond transparent au scroll */
.navbar.scrolled {
  background-color: rgba(255, 255, 255, 0.95); 
  backdrop-filter: blur(10px);
}

/* Animation de transition */
.navbar, .navbar-brand, .navbar-logo, .nav-link {
  transition: all 0.3s ease-in-out;
}

/* Ajoutez ces styles à Header.css */

.signup-dropdown-container {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.signup-button {
  background-color: white !important;
  color: #bd193b !important;
  padding: 0.6rem 1.5rem !important;
  border-radius: 25px;
  transition: all 0.3s ease;
  border: 2px solid #bd193b;
  box-shadow: 0 2px 4px rgba(189, 25, 59, 0.2);
  margin-right: 1.5rem;
}

.signup-button:hover {
  background-color: #bd193b !important;
  color: white !important;
}

/* Menu déroulant d'inscription */
.signup-dropdown-container {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.signup-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  min-width: 220px;
  padding: 0.5rem 0;
  z-index: 1000;
  margin-top: 0.5rem;
  animation: dropdownFade 0.2s ease-out;
}

.dropdown-item {
  display: block;
  padding: 0.75rem 1.5rem;
  color: #333;
  text-decoration: none;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: rgba(189, 25, 59, 0.1);
  color: #bd193b;
}

/* Animation du dropdown */
@keyframes dropdownFade {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Très petits écrans */
@media (max-width: 360px) {
  .navbar {
    height: 60px;
  }

  .navbar-brand {
    padding-left: 0.5rem;
  }
}

/* Styles spécifiques pour tablette */
/* Styles spécifiques pour tablette */
@media screen and (min-width: 768px) and (max-width: 991px) {
  /* Navbar principale */
  .navbar {
    height: 70px;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, .95);
    z-index: 1000;
  }

  /* Bouton hamburger */
  .navbar-toggler {
    display: block !important;
    margin-right: 2rem;
  }

  /* Logo */
  nav .navbar-logo {
    width: 90px;
    height: 90px;
    margin: 0;
  }

  /* Container du menu déroulant */
  nav .navbar-collapse {
    position: fixed; /* Changé de absolute à fixed */
    top: 70px; /* Aligné avec la hauteur de la navbar */
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, .95);
    z-index: 999;
    max-height: calc(100vh - 70px); /* Hauteur maximale */
    overflow-y: auto; /* Permettre le défilement si nécessaire */
  }

  /* Liste de navigation */
  nav .navbar-nav {
    flex-direction: column !important;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    margin: 0;
  }

  /* Éléments de navigation */
  nav .nav-item {
    width: 100%;
    text-align: center;
    padding: 0.5rem 0;
  }

  nav .nav-link {
    display: block;
    width: 100%;
    text-align: center;
    margin: 5px 0;
    padding: 0.5rem 0 !important;
  }

  /* Boutons de connexion et inscription */
  nav .login-button,
  .signup-button {
    display: inline-block;
    width: 80%;
    margin: 0.5rem auto;
    text-align: center;
  }

  /* Icône du panier */
  .cart-icon-container {
    margin: 1rem auto;
  }

  /* Les deux derniers éléments de la liste */
  .navbar-nav > li:nth-last-child(-n+2) {
    display: block !important;
    width: 100%;
  }
}