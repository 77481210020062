/* BeauticianPortalHeader.css */
.app-header {
    height: var(--header-height);
    background: #fff;
    border-bottom: 1px solid #eaecef;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  
  .header-wrapper {
    max-width: 1440px;
    height: 100%;
    margin: 0 auto;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
  
  /* Left section */
  .header-left {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .menu-button {
    padding: 0.5rem;
    background: none;
    border: none;
    color: #4b5563;
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.2s;
    display: none;
  }
  
  .menu-button:hover {
    background-color: #f3f4f6;
    color: #1f2937;
  }

  /* Right section */
  .header-right {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .icon-button {
    position: relative;
    padding: 0.5rem;
    background: none;
    border: none;
    color: #4b5563;
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.2s;
  }
  
  .icon-button:hover {
    background-color: #f3f4f6;
    color: #1f2937;
  }
  
  .notification-indicator {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 18px;
    height: 18px;
    background-color: var(--primary);
    border-radius: 50%;
    color: #fff;
    padding: 3px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* User menu */
  .user-profile-button {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.5rem 1rem;
    background: none;
    border: 1px solid #eaecef;
    color: #4b5563;
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.2s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .user-profile-button:hover {
    background-color: #f9fafb;
    border-color: #dde0e4;
  }
  
  .user-name {
    font-size: 0.875rem;
    font-weight: 500;
    color: #1f2937;
  }
  
  /* Logout button */
  .logout-button {
    padding: 0.5rem;
    background: none;
    border: none;
    color: #4b5563;
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.2s;
  }
  
  .logout-button:hover {
    background-color: #fee2e2;
    color: var(--primary);
  }
  
  /* Dropdowns */
  .dropdown-menu {
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0;
    background: white;
    border-radius: 12px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border: 1px solid #eaecef;
    width: 320px;
    overflow: hidden;
  }
  
  .dropdown-header {
    padding: 1rem;
    border-bottom: 1px solid #eaecef;
  }
  
  .dropdown-header h3 {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 600;
    color: #1f2937;
  }
  
  .notification-items {
    max-height: 360px;
    overflow-y: auto;
  }
  
  .notification-item {
    padding: 0.875rem 1rem;
    border-bottom: 1px solid #eaecef;
    transition: background-color 0.2s;
  }
  
  .notification-item:hover {
    background-color: #f9fafb;
  }
  
  .notification-info {
    display: flex;
    gap: 1rem;
  }
  
  .notification-time {
    font-size: 0.75rem;
    color: #6b7280;
    white-space: nowrap;
  }
  
  .notification-message {
    font-size: 0.875rem;
    color: #4b5563;
    margin: 0;
    line-height: 1.4;
  }
  
  .notification-message strong {
    color: #1f2937;
    font-weight: 500;
  }

  /* Ajoutez ces styles dans BeauticianPortalHeader.css */
.user-profile-button {
  position: relative; /* Ajouté pour positionner l'indicateur */
}

.message-indicator {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 24px;
  height: 24px;
  background-color: #bd193b;
  border: 4px solid white;
  border-radius: 50%;
  animation: pulse 2s infinite;
}

.dropdown-link {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  color: #4b5563;
  text-decoration: none;
  transition: all 0.2s ease;
  border-radius: 8px;
  position: relative;
  margin: 0.25rem 0;
}

.dropdown-link:hover {
  background-color: #f3f4f6;
  color: #1f2937;
}

.message-count {
  position: absolute;
  right: 12px;
  background-color: #bd193b;
  color: white;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0 6px;
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.1); opacity: 0.8; }
  100% { transform: scale(1); opacity: 1; }
}
  
  /* Responsive design */
  @media (max-width: 768px) {

    .menu-button {
      display: block;
    }
  
    .user-name {
      display: none;
    }
  
    .header-wrapper {
      padding: 0 1rem;
    }
  
    .dropdown-menu {
      position: fixed;
      top: var(--header-height);
      right: 0;
      left: 0;
      width: 100%;
      border-radius: 0;
      border-left: none;
      border-right: none;
    }
  }

  