/* Modal Styles */
.establishment-modal .modal-content {
    border: none;
    border-radius: 20px;
    overflow: hidden;
    max-width: 800px;
    height: 90vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  
  /* Form Container */
  .form-wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: white;
  }
  
  /* Scrollable Content */
  .form-scrollable-content {
    flex: 1;
    overflow-y: auto;
    padding: 2rem;
  }
  
  /* Custom Scrollbar */
  .form-scrollable-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .form-scrollable-content::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  .form-scrollable-content::-webkit-scrollbar-thumb {
    background: #bd193b;
    border-radius: 4px;
  }
  
  .form-scrollable-content::-webkit-scrollbar-thumb:hover {
    background: #a61533;
  }
  
  /* Close Button */
  .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    color: #666;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    z-index: 10;
  }
  
  .close-button:hover {
    background: #f3f4f6;
    color: #1a1a1a;
  }
  
  /* Header Section */
  .form-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .form-header h2 {
    font-size: 1.8rem;
    font-weight: 700;
    color: #1a1a1a;
    margin-bottom: 0.5rem;
  }
  
  .form-header p {
    color: #666;
    font-size: 1rem;
    margin: 0;
  }
  
  /* Form Layout */
  .establishment-form {
    width: 100%;
  }
  
  .form-grid {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  /* Input Groups */
  .input-group {
    width: 100%;
  }
  
  /* Input Fields */
  .form-control {
    width: 100%;
    height: 45px;
    padding: 0.75rem 1rem;
    border: 2px solid #e5e7eb;
    border-radius: 10px;
    font-size: 0.95rem;
    transition: all 0.2s ease;
    background: white;
  }
  
  .form-control:focus {
    border-color: #bd193b;
    box-shadow: 0 0 0 3px rgba(189, 25, 59, 0.1);
    outline: none;
  }
  
  .form-control::placeholder {
    color: #9ca3af;
  }
  
  /* Phone Input Specific Styles */
  .phone-input-wrapper {
    position: relative;
    height: 45px;
  }
  
  .phone-container {
    width: 100%;
  }
  
  .phone-container .form-control {
    padding-left: 48px !important;
  }
  
  .phone-container input {
    width: 100% !important;
    height: 45px !important;
    padding: 0.75rem 1rem !important;
    padding-left: 48px !important;
    border: 2px solid #e5e7eb !important;
    border-radius: 10px !important;
    font-size: 0.95rem !important;
    background: white !important;
  }
  
  .phone-container input:focus {
    border-color: #bd193b !important;
    box-shadow: 0 0 0 3px rgba(189, 25, 59, 0.1) !important;
    outline: none !important;
  }
  
  .phone-button {
    background: transparent !important;
    border: none !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    height: 45px !important;
    width: 41px !important;
    padding: 0 8px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  
  /* Submit Button Container */
  .submit-button-container {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 2rem;
    background: white;
    border-top: 1px solid #e5e7eb;
  }
  
  /* Submit Button */
  .submit-button {
    width: 100%;
    padding: 0.875rem;
    background: #bd193b;
    color: white;
    border: none;
    border-radius: 10px;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .submit-button:hover {
    background: #a61533;
    transform: translateY(-1px);
  }
  
  .submit-button:active {
    transform: translateY(1px);
  }
  
  /* Responsive Design */
  @media (max-width: 576px) {
    .establishment-modal .modal-content {
      height: 100vh;
      border-radius: 0;
    }
  
    .form-scrollable-content {
      padding: 1.5rem 1rem;
    }
  
    .submit-button-container {
      padding: 1rem;
    }
  
    .form-header h2 {
      font-size: 1.5rem;
    }
  }