.cg-section {
    position: relative;
    height: 300px;
    width: 100%;
    margin-top: 0;
    overflow: hidden;
}

.cg-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.2)
    );
}

.cg-content {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
}

.cg-content h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.content-wrapper {
    padding: 4rem 0;
    background-color: #ffffff;
}

.document-selector {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.selector-btn {
    padding: 0.75rem 2rem;
    font-size: 1.1rem;
    border: 2px solid #bd193b;
    background: transparent;
    color: #bd193b;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.selector-btn.active {
    background: #bd193b;
    color: white;
}

.selector-btn:hover {
    background: #bd193b;
    color: white;
    transform: translateY(-2px);
}

.pdf-viewer {
    width: 100%;
    height: calc(100vh - 400px);
    min-height: 800px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.pdf-document {
    width: 100%;
    height: 100%;
    border: none;
}

.pdf-fallback {
    padding: 2rem;
    text-align: center;
    color: #666;
}

.pdf-fallback a {
    color: #bd193b;
    text-decoration: none;
}

.pdf-fallback a:hover {
    text-decoration: underline;
}

/* ... Styles précédents ... */

.pdf-viewer {
    width: 100%;
    height: calc(100vh - 400px);
    min-height: 800px;
    background: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
}

.pdf-document {
    width: 100%;
    height: 100%;
    border: none;
    background: white;
}

.pdf-fallback {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 2rem;
}

.download-btn {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.75rem 2rem;
    background-color: #bd193b;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: all 0.3s ease;
}

.download-btn:hover {
    background-color: #a31532;
    transform: translateY(-2px);
}

@media (max-width: 768px) {
    .cg-section {
        height: 200px;
        margin-top: 70px;
    }

    .cg-content h1 {
        font-size: 2rem;
    }

    .content-wrapper {
        padding: 2rem 0;
    }

    .pdf-viewer {
        min-height: 600px;
    }
    
    .selector-btn {
        padding: 0.5rem 1.5rem;
        font-size: 1rem;
    }
}