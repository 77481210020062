/* Conteneur principal */
.gallery-container {
    width: 100%;
    margin-top: 1rem;
    padding-top: 1rem;
  }
  
  .gallery-layout {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  @media (min-width: 768px) {
    .gallery-layout {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  /* Carousel principal */
  .main-carousel {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    height: 400px;
  }
  
  .carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .carousel-image:hover {
    transform: scale(1.02);
  }
  
  /* Boutons de navigation du carousel */
  .carousel-nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.9);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2;
  }
  
  .carousel-nav-btn:hover {
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .carousel-nav-btn.prev {
    left: 16px;
  }
  
  .carousel-nav-btn.next {
    right: 16px;
  }
  
  /* Services Grid */
  .prestation-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, minmax(0, 1fr));
    gap: 1rem;
    height: 400px;
  } 
 
  /* Modifications pour la dernière miniature */
.miniature-item.last-thumbnail img {
  filter: blur(2px);
}

.remaining-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  text-align: center;
}

.remaining-overlay:hover {
  background: rgba(0, 0, 0, 0.7);
}

.remaining-overlay span {
  font-size: 1.1rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.miniature-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  /* Retirer aspect-ratio: 1 */
}

/* Pour l'image elle-même */
.miniature-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;  /* Changé de 'cover' à 'fill' */
  transition: transform 0.3s ease;
}
  
  .miniature-item:hover img {
    transform: scale(1.05);
  }
  
  .gallery-popup {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.95);
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: fadeIn 0.3s ease forwards;
  }
  
  .gallery-popup-content {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1400px; /* Augmenté pour de plus grandes images */
    max-height: 90vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }
  
  .popup-image {
    max-width: 100%;
    max-height: 90vh;
    width: auto; /* Ajouté pour maintenir le ratio */
    height: auto; /* Ajouté pour maintenir le ratio */
    object-fit: contain;
    opacity: 0;
    animation: fadeIn 0.3s ease 0.2s forwards;
  }
  
  /* Boutons de navigation du popup */
  .popup-nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    color: white;
  }
  
  .popup-nav-btn:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  .popup-nav-btn.prev {
    left: 24px;
  }
  
  .popup-nav-btn.next {
    right: 24px;
  }
  
  /* Bouton de fermeture */
  .popup-close {
    position: absolute;
    top: 90%; /* Descendu plus bas que les flèches qui sont à 50% */
    right: 90px;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    color: white;
    z-index: 70;
    transform: translateY(-50%); /* Garde le centrage du bouton lui-même */
  }
  
  .popup-close:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  
  /* Positionnement spécifique du X */
  .popup-close {
    transform: translateY(-50%); /* Centre vertical */
    right: 90px; /* Distance depuis la droite, après la flèche droite */
  }
  
  /* Style de l'icône X pour la rendre plus visible */
  .popup-close svg {
    width: 28px; /* Icône plus grande */
    height: 28px;
    stroke-width: 2.5; /* Trait plus épais */
  }
  
  /* Counter */
  .popup-counter {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 14px;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .gallery-layout {
      gap: 0.5rem;
    }
  
    .main-carousel,
    .prestation-grid {
      height: 300px;
    }
  
    .popup-nav-btn {
      width: 40px;
      height: 40px;
    }
  
    .popup-close {
      top: 60px;
      right: 16px;
    }
  
    .popup-counter {
      bottom: 16px;
    }
  }
  
  @media (max-width: 640px) {
    .prestation-grid {
      height: auto;
    }
  }