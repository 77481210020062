:root {
    --primary: #bd193b;
    --primary-light: rgba(189, 25, 59, 0.1);
    --gray-50: #f8f9fa;
    --gray-100: #f1f3f5;
    --gray-200: #e9ecef;
    --gray-600: #868e96;
    --gray-700: #495057;
    --gray-900: #212529;
  }
  
  .about-container {
    max-width: 1300px;
    margin: 1rem auto;
    padding: 2rem;
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    box-shadow: 0 4px 25px rgba(189, 25, 59, 0.1);
  }
  
  .about-title {
    font-size: 1.5rem;
    color: var(--gray-900);
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
  
  .about-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
  
  .about-description-container {
    background: white;
    border-radius: 1rem;
    padding: 1rem 0;
    text-align: justify;
  }
  
  /* Modifier le conteneur du texte dans About.jsx */
  .about-text {
    text-align: center;
    font-size: 1rem;
    line-height: 1.6;
    color: var(--gray-700);
  }

  .review-card {
    background: white;
    border-radius: 1rem;
    padding: 1.5rem;
    box-shadow: 0 4px 25px rgba(189, 25, 59, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .review-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 30px rgba(189, 25, 59, 0.15);
  }
  
  .review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .star-rating {
    display: flex;
    gap: 0.25rem;
  }
  
  .star {
    transition: all 0.2s ease;
  }
  
  .reviewer-name {
    font-weight: 500;
    color: var(--gray-900);
  }
  
  .review-content {
    color: var(--gray-700);
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  
  .review-date {
    display: block;
    font-size: 0.9rem;
    color: var(--gray-600);
  }
  
  /* Modal styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease;
  }
  
  .modal-content {
    background: white;
    border-radius: 1rem;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    position: relative;
    animation: slideUp 0.3s ease;
  }
  
  .modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    color: var(--gray-600);
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
  }
  
  .modal-close:hover {
    background: var(--gray-100);
    color: var(--gray-900);
  }
  
  .modal-about {
    padding: 2rem;
  }
  
  .modal-about h2 {
    font-size: 1.5rem;
    color: var(--gray-900);
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
  
  .modal-about-list {
    max-height: calc(90vh - 8rem);
    overflow-y: auto;
    padding-right: 1rem;
  }
  
  .modal-review {
    padding: 1.5rem;
    border-bottom: 1px solid var(--gray-200);
    transition: background-color 0.2s ease;
  }
  
  .modal-review.highlighted {
    background: var(--primary-light);
  }
  
  .modal-review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .modal-review-info {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  /* Scrollbar styling */
  .modal-about-list::-webkit-scrollbar {
    width: 6px;
  }
  
  .modal-about-list::-webkit-scrollbar-track {
    background: var(--gray-100);
    border-radius: 3px;
  }
  
  .modal-about-list::-webkit-scrollbar-thumb {
    background: var(--gray-400);
    border-radius: 3px;
  }
  
  .modal-about-list::-webkit-scrollbar-thumb:hover {
    background: var(--gray-500);
  }
  
/* Ajoutez ces styles à votre fichier about.css existant */

.about-carousel {
    position: relative;
    padding: 0 3rem;
  }
  
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: white;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    color: var(--gray-700);
  }
  
  .carousel-button:hover {
    background: var(--primary);
    color: white;
    box-shadow: 0 4px 15px rgba(189, 25, 59, 0.3);
  }
  
  .carousel-button.prev {
    left: 0;
  }
  
  .carousel-button.next {
    right: 0;
  }
  
  .about-grid {
    transition: transform 0.5s ease;
  }
  
  .carousel-dots {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1.5rem;
  }
  
  .carousel-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--gray-300);
    border: none;
    padding: 0;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .carousel-dot.active {
    background: var(--primary);
    transform: scale(1.2);
  }
  
  .about-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    transition: opacity 0.3s ease;
  }
  
  /* Animation pour la transition des about */
  @keyframes fadeSlide {
    from {
      opacity: 0;
      transform: translateX(20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .review-card {
    animation: fadeSlide 0.5s ease forwards;
  }
  
  /* Media Queries */
  @media (max-width: 992px) {
    .about-carousel {
      padding: 0 2.5rem;
    }
  }
  
  @media (max-width: 768px) {
    .about-carousel {
      padding: 0 2rem;
    }
    
    .carousel-button {
      width: 35px;
      height: 35px;
    }
  }
  
  @media (max-width: 480px) {
    .about-carousel {
      padding: 0 1.5rem;
    }
    
    .carousel-dots {
      margin-top: 1rem;
    }
  }
  
  /* Media Queries */
  @media (max-width: 1200px) {
    .about-container {
      padding: 1.5rem;
    }
  }
  
  @media (max-width: 992px) {
    .about-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .about-grid {
      grid-template-columns: 1fr;
    }
  
    .modal-content {
      width: 95%;
      max-height: 95vh;
    }
  }
  
  @media (max-width: 480px) {
    .about-container {
      padding: 1rem;
    }
  
    .modal-about {
      padding: 1.5rem;
    }
  
    .modal-review {
      padding: 1rem;
    }
  }