.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 9999;
    backdrop-filter: blur(5px);
  }
  
  .loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  
  .loader-circle {
    position: absolute;
    border: 4px solid #bd193b;
    opacity: 1;
    border-radius: 50%;
    animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  
  .loader-inner {
    animation-delay: -0.5s;
  }
  
  @keyframes loader {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
  
  /* Animation de sortie */
  .loader-container.fade-out {
    animation: fadeOut 0.5s ease forwards;
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      visibility: hidden;
    }
  }