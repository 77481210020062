.search-bar-wrapper {
  position: relative;
  width: 100%;
  z-index: 9999;
}

.search-bar-container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  position: relative;
}

.search-input-group {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 20px;
  padding: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border: 2px solid transparent;
  transition: all 0.3s ease;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;

}

.search-input-group:focus-within {
  border-color: #bd193b;
  box-shadow: 0 6px 24px rgba(189, 25, 59, 0.15);
}

.search-input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 1.1rem;
  padding: 12px 20px;
  background: transparent;
  width: 100%;
  color: #333;
}

.search-input::placeholder {
  color: #9ca3af;
}

/* Bouton de recherche */
.search-button {
  color: white;
  transition: all 0.3s ease;
  width: 48px;
  height: 48px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #bd193b;
  border: none;
  border-radius: 20%;
}

.search-button:hover {
  background: #961530;
  transform: scale(1.05);
}

/* Container des suggestions */
.suggestions-dropdown {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-height: 400px;
  overflow-y: auto;
  z-index: 1000;
  border: 1px solid rgba(0, 0, 0, 0.1);
  animation: slideIn 0.2s ease-out;
}

/* Items de suggestion */
.suggestion-item {
  display: flex;
  align-items: center;
  padding: 14px 16px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease;
}

.suggestion-item:last-child {
  border-bottom: none;
}

.suggestion-item:hover,
.suggestion-item.selected {
  background-color: rgba(189, 25, 59, 0.05);
}

.suggestion-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.suggestion-text {
  color: #1F2937;
  font-size: 0.95rem;
  font-weight: 500;
}

.suggestion-subtext {
  color: #6B7280;
  font-size: 0.85rem;
}


/* Base des suggestions inchangée... */
.suggestion-type {
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  padding: 0 8px;
}

/* Style pour Prestation (P) */
.suggestion-type.prestation {
  background-color: #FF90BC;
  color: white;
}

/* Style pour Business (B) */
.suggestion-type.business {
  background-color: #bd193b;
  color: white;
}

/* Style pour Ville (V) */
.suggestion-type.ville {
  background-color: #7FC7D9;
  color: white;
}

.suggestion-type.sous-categorie {
  background-color: #83A2FF; /* Bleu distinct */
  color: white;
  min-width: 32px; /* Un peu plus large pour accommoder "SP" */
  padding: 0 8px;
}

/* États de chargement et vide */
.suggestion-loading {
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #6B7280;
}

.search-input-section {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 10px;
  position: relative;
}

.search-input-section:first-child {
  flex: 0 0 60%;
  border-right: 1px solid rgba(0, 0, 0, 0.1); 
  padding-right: 10px;
}

.search-input-section:nth-child(2) {
  flex: 0 0 30%;
  padding-left: 0;
} 

/* .search-input-section:first-child {
  flex: 0 0 50%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding-right: 0;
  min-width: 0; 
}

.search-input-section:nth-child(2) {
  flex: 0 0 35%;
  padding-left: 0;
  min-width: 0;
} */

.loading-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #bd193b;
  animation: spin 1s linear infinite;
}

.service-suggestions {
  left: 0;
  right: 52%;
  margin-right: 4px;
}

.location-suggestions {
  left: 48%;
  right: 0;
  margin-left: 4px;
}

.search-input-section.has-selection input {
  color: #bd193b;
  font-weight: 500;
}

.suggestions-dropdown {
  top: calc(100% + 12px); /* Augmenté légèrement */
}

.suggestion-empty {
  padding: 20px;
  text-align: center;
  color: #6B7280;
  font-style: italic;
}

/* Scrollbar personnalisée */
.suggestions-dropdown::-webkit-scrollbar {
  width: 8px;
}

.suggestions-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.suggestions-dropdown::-webkit-scrollbar-thumb {
  background: rgba(189, 25, 59, 0.3);
  border-radius: 4px;
}

.suggestions-dropdown::-webkit-scrollbar-thumb:hover {
  background: rgba(189, 25, 59, 0.5);
}

.search-icon {
  display: flex;
  align-items: center;
  color: #6B7280;
  margin-right: 8px;
  min-width: 16px;
}

/* Animations */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive */
@media (max-width: 768px) {
  .search-bar-container {
    margin: 0;
  }

  .search-input {
    font-size: 1rem;
    padding: 10px 15px;
  }

  .search-button {
    width: 42px;
    height: 42px;
  }

  .suggestion-text {
    font-size: 0.9rem;
  }

  .suggestion-subtext {
    font-size: 0.8rem;
  }

  .suggestion-type {
    font-size: 0.7rem;
    padding: 4px 8px;
  }

  .search-bar-container {
    min-width: 310px;
    margin: 0 10px;
  }

  .search-input-section {
    min-width: 0;
  }

  .search-input {
    width: 100%;
    min-width: 0;
  }

  .service-suggestions,
  .location-suggestions {
    left: 0;
    right: 0;
    margin: 0;
  }
  .search-input-section:first-child {
    flex: 1;
    border-right: none;
    padding-right: 10px;
  }
  
  .search-input-section:nth-child(2) {
    display: none;
  }
}

/* Accessibilité */
@media (prefers-reduced-motion: reduce) {
  .search-button,
  .suggestion-item,
  .suggestions-dropdown {
    animation: none;
    transition: none;
  }

  .loading-spinner {
    animation: none;
  }
}

@media (max-width: 540px) {
  /* .search-input-section {
    gap: 10px;
    padding: 0;
  }

  .search-bar-container {
    min-width: 320px;
    margin: 0 10px;
    padding: 0;
  }

  .search-input-section {
    padding: 0 5px;
  }

  /* Réduire les marges intérieures des inputs */
  .search-input {
    padding: 10px 8px;
  }

  /* Ajuster le bouton de recherche */
  .search-button {
    margin-left: 5px;
    min-width: 42px;
  }

  .search-icon {
    margin-right: 4px;
  }
}