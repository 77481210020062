/* src/components/booking/DateSelection.css */
.date-selection {
    padding: 2rem;
    min-height: calc(100vh - 80px);
    background-color: var(--gray-50);
    padding-bottom: 100px;
  }
  
  .date-selection-header {
    max-width: 1200px;
    margin: 0 auto 2rem;
    padding: 1rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .total-duration {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--gray-700);
    font-weight: 500;
  }
  
  .dates-navigation {
    max-width: 1200px;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    background: white;
    padding: 1rem;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .nav-button {
    background: none;
    border: none;
    color: var(--gray-600);
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    transition: all 0.3s ease;
  }
  
  .nav-button:hover {
    background: var(--gray-100);
    color: var(--gray-900);
  }
  
  .dates-container {
    display: flex;
    gap: 0.5rem;
    overflow-x: auto;
    padding: 0.5rem;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .dates-container::-webkit-scrollbar {
    display: none;
  }
  
  .date-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem 1.25rem;
    border: 2px solid var(--gray-200);
    border-radius: 8px;
    background: white;
    cursor: pointer;
    transition: all 0.3s ease;
    min-width: 100px;
  }
  
  .date-button:hover {
    border-color: var(--primary);
  }
  
  .date-button.selected {
    background: var(--primary);
    color: white;
    border-color: var(--primary);
  }
  
  .day {
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
  }
  
  .date {
    font-weight: 600;
  }
  
  .timeslots-container {
    max-width: 1200px;
    margin: 0 auto;
    background: white;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .timeslots-container h3 {
    margin-bottom: 1.5rem;
    color: var(--gray-900);
  }
  
  .timeslots-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 1rem;
  }
  
  .timeslot-button {
    padding: 0.75rem;
    border: 2px solid var(--gray-200);
    border-radius: 8px;
    background: white;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .timeslot-button:hover {
    border-color: var(--primary);
  }
  
  .timeslot-button.selected {
    background: var(--primary);
    color: white;
    border-color: var(--primary);
  }
  
  .confirmation-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .selected-datetime {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .selected-date {
    font-weight: 600;
    color: var(--gray-900);
  }
  
  .selected-time {
    color: var(--gray-600);
  }
  
  .confirm-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background: var(--primary);
    color: white;
    border: none;
    border-radius: 25px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .confirm-button:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(189, 25, 59, 0.2);
  }
  
  .confirm-button:disabled {
    background: var(--gray-400);
    cursor: not-allowed;
  }
  
  @media (max-width: 768px) {
    .date-selection {
      padding: 1rem;
    }
  
    .dates-navigation {
      padding: 0.75rem;
    }
  
    .date-button {
      min-width: 90px;
      padding: 0.5rem 1rem;
    }
  
    .timeslots-grid {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
  
    .confirmation-bar {
      padding: 1rem;
      flex-direction: column;
      gap: 1rem;
    }
  
    .selected-datetime {
      text-align: center;
    }
  
    .confirm-button {
      width: 100%;
      justify-content: center;
    }
  }

  @media (max-width: 480px) {
    .date-selection {
      padding: 0;
    }
  }