/* src/components/BusinessDropdown/BusinessDropdown.css */
.business-dropdown-container {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .business-dropdown-menu {
    position: absolute;
    top: calc(100% + 12px);
    right: 0;
    background: linear-gradient(135deg, #ffffff, #f7f7f7);
    border-radius: 16px;
    box-shadow: 
      0 10px 20px rgba(0, 0, 0, 0.15),
      0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 1.5rem;
    width: 340px;
    z-index: 1000;
    opacity: 0;
    transform: translateY(-20px) scale(0.95);
    animation: businessDropdownSlideIn 0.4s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }
  
  @keyframes businessDropdownSlideIn {
    from {
      opacity: 0;
      transform: translateY(-20px) scale(0.95);
    }
    to {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
  
  .business-dropdown-menu .dropdown-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 12px;
    transition: all 0.3s ease;
    background: transparent;
    color: #333;
    text-decoration: none;
    gap: 12px;
  }
  
  .business-dropdown-menu .dropdown-item:hover {
    background: rgba(189, 25, 59, 0.1);
    transform: translateX(8px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  }
  
  /* Adaptation mobile */
  @media (max-width: 768px) {
    .business-dropdown-container {
      width: 100%;
    }
    
    .business-dropdown-menu {
      position: static;
      width: 100%;
      box-shadow: none;
      margin: 0;
      background-color: rgba(189, 25, 59, 0.05);
      border-radius: 0;
    }
  }