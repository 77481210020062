/* src/pages/beautician/BeauticianProfile.css */
:root {
  --header-height: 70px;
  --primary: #bd193b;
  --primary-light: rgba(189, 25, 59, 0.1);
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-700: #374151;
  --gray-900: #111827;
}

.profile-section {
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem;
  background: white;
  position: relative;
  border-bottom: 1px solid var(--gray-100);
  height: auto; /* Permet de s'adapter au contenu */
  min-height: 0; /* Supprime la hauteur minimale imposée */
  padding-bottom: 0; /* Supprime les espaces inutiles */
}

.profile-section:empty {
  height: auto;
  min-height: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .profile-section {
    padding: 1rem;
    padding-bottom: 0; /* Supprime le padding bas */
  }
}

.to-hide:empty {
  display: none;
}