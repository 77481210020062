/* Styles généraux */
.calendar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  padding: 1rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Stylisation des événements */
.fc-event {
  border-radius: 8px !important;
  padding: 2px;
  font-size: 0.85rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white !important;
  border: none !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fc-event-main {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

/* Ajout d'une animation pour le hover des événements */
.fc-event:hover {
  transform: scale(1.02);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.event-blue-light {
  background-color: #4a90e2 !important; /* Bleu clair */
  border-color: #4a90e2 !important;
}

.event-blue-dark {
  background-color: #1e64c8 !important; /* Bleu foncé */
  border-color: #1e64c8 !important;
}

/* Centrage du titre */
.fc-toolbar-title {
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
  color: #2d3748;
}

.more-events {
  color: #007bff;
  font-weight: bold;
  cursor: pointer;
  padding: 2px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}

.fc-timegrid-more-link {
  top: 60px !important;
  bottom: -90px !important;
}

.more-events:hover {
  color: #004a9a;
}

.fc-event-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* Ajuste selon la taille souhaitée */
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

/* .fc-event:hover .fc-event-title {
  white-space: normal;
  max-width: none;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  z-index: 10;
} */

.fc .fc-col-header-cell-cushion {
  text-decoration: none!important;
  color: #bd193b !important;
}

.fc .fc-daygrid-day-number {
  text-decoration: none!important;
  color: #bd193b !important;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: transparent!important;
}

.event-title {
  font-size: 0.87rem;
  margin-left: 10px;
}

/* Styles pour les heures de fermeture */
.closed-hours {
  border-radius: 0 !important;
  pointer-events: none !important; /* Désactive les clics */
  opacity: 0.5 !important; /* Rend l'événement plus discret */
  background: repeating-linear-gradient(
      45deg,
      #f9f9f9,
      #f9f9f9 10px,
      #f1f1f1 10px,
      #f1f1f1 20px
  ) !important; /* Ajoute un effet visuel en diagonal */
}

/* Styles pour la pause déjeuner */
.lunch-break {
  border-radius: 0 !important;
  pointer-events: none !important;
  opacity: 0.8 !important;
  color: #0015fb !important;
  background: repeating-linear-gradient(
      45deg,
      #ffcccb,
      #ffcccb 10px,
      #ff9999 10px,
      #ff9999 20px
  ) !important; /* Ajoute un effet visuel en diagonal */
}

.fully-blocked-day {
  background-color: #d3d3d3 !important; /* Rouge vif */
  opacity: 0.8 !important;
  border-radius: 0 !important;
  color: #0015fb !important;
  pointer-events: none; /* Désactive les clics */
}

.blocked-hours {
  background-color: #d3d3d3 !important; /* Rouge clair */
  opacity: 0.8 !important;
  border-radius: 0 !important;
  color: #0015fb !important;
  pointer-events: none;
}

@media (max-width: 768px) {
  .calendar-container {
    padding: 0 !important;
  }

  .fc-toolbar {
    margin-bottom: 1em;
    /* flex-direction: column !important; */
    gap: 10px !important;
  }

  .fc-button {
    font-size: 0.8rem !important;
    padding: 4px !important;
  }

  .fc-header-toolbar {
    /* flex-direction: column !important; */
    align-items: center !important;
  }

  .fc-button {
    font-size: 11px !important; /* Réduit la taille des boutons */
    padding: 4px !important;
  }

  .fc-toolbar-title {
    text-transform: capitalize;
    font-size: 14px !important; /* Réduit la taille du titre */
  }

  .fc-view-harness {
    overflow-x: scroll !important; /* Ajoute un scroll horizontal si nécessaire */
  }

  .more-events {
    font-size: 10px;
  }

  .event-title {
    font-size: 0.7rem;
  } 
}

.fc-popover {
  transform: translateX(-4vw) !important;
}

/* Animation pour la modale */
.modal-enter {
  opacity: 0;
  transform: scale(0.9);
}

.modal-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.modal-exit {
  opacity: 1;
}

.modal-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}