.search-page {
  min-height: calc(100vh);
  background-color: #f8f9fa;
}

.search-container {
  display: grid;
  grid-template-columns: minmax(280px, 20%) minmax(400px, 40%) minmax(400px, 40%);
  gap: 1.5rem;
  padding: 1.5rem;
  height: calc(100vh);
  max-width: 100%;
  margin: 0 auto;
}

/* Responsive Design */
@media (max-width: 1400px) {
  .search-container {
    grid-template-columns: 280px minmax(380px, 40%) minmax(380px, 40%);
  }
}

@media (max-width: 1200px) {
  .search-container {
    grid-template-columns: 280px 1fr;
  }
  
  .map-column {
    display: none;
  }
}

@media (max-width: 992px) {
  .search-container {
    grid-template-columns: 1fr;
    gap: 1rem;
    height: auto;
  }
  
  .filters-column {
    height: auto;
    position: static;
    margin-bottom: 1rem;
  }
  
  .beauticians-grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
  
  .results-column {
    height: auto;
  }
}

@media (max-width: 576px) {
  .search-container {
    padding: 1rem;
  }
  
  .filters-section h3 {
    font-size: 1rem;
  }
  
  .pagination-controls {
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;
  }
  
  .pagination-info {
    text-align: center;
    order: -1;
  }
}

/* À ajouter à la fin de Search.css */
.toggle-filters-btn {
  display: none; /* Caché par défaut */
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(to right, #bd193b, #a00f2f);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(189, 25, 59, 0.2);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}

.toggle-filters-btn:hover {
  transform: translateX(-50%) translateY(-2px);
  box-shadow: 0 4px 12px rgba(189, 25, 59, 0.3);
}

.filters-column.hidden {
  transform: translateY(-100%);
  opacity: 0;
  pointer-events: none;
}

.toggle-filters-btn {
  display: none; /* Le bouton reste caché par défaut */
}

@media (max-width: 992px) {
  .toggle-filters-btn {
    display: flex;
  }
  
  .filters-column {
    position: fixed;
    top: 70px; /* Ajouté cette ligne pour laisser de l'espace pour le header */
    left: 0;
    right: 0;
    z-index: 999;
    transition: transform 0.3s ease, opacity 0.3s ease;
    max-height: calc(80vh - 70px); /* Ajusté pour tenir compte du décalage */
    overflow-y: auto;
    background: white; /* Assurez-vous que le fond est bien blanc */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Optionnel: ajoute une ombre pour mieux délimiter */
  }

  .filters-column.hidden {
    transform: translateY(-100%);
    opacity: 0;
    pointer-events: none;
  }
}