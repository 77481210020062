/* PaymentOptions.css */
.payment-container {
    width: 100%;
    max-width: 800px; /* Réduit de 1200px */
    margin: 0 auto;
    /* padding: 0 15px; */
  }
  
  .payment-title {
    font-size: 1.25rem; /* Réduit de 1.5rem */
    font-weight: 600;
    color: #2d3748;
    margin-bottom: 1.5rem; /* Réduit de 2rem */
  }
  
  .payment-options-wrapper {
    display: flex;
    gap: 1rem; /* Réduit de 1.5rem */
    flex-wrap: wrap;
    padding-bottom: 0.75rem;
    -webkit-overflow-scrolling: touch;
  }
  
  .payment-card {
    flex: 1;
    min-width: 180px; /* Réduit de 300px */
    position: relative;
    padding: 1rem; /* Réduit de 1.5rem */
    border-radius: 12px; /* Réduit de 16px */
    border: 1px solid #e2e8f0; /* Réduit de 2px */
    background: #ffffff;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;
  }
  
  .payment-card:hover {
    transform: translateY(-1px); /* Réduit de -2px */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  }
  
  .payment-content {
    display: flex;
    align-items: center;
    gap: 0.75rem; /* Réduit de 1.25rem */
  }
  
  .icon-wrapper {
    width: 32px; /* Réduit de 48px */
    height: 32px; /* Réduit de 48px */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px; /* Réduit de 12px */
    transition: all 0.3s ease;
  }
  
  .icon-wrapper svg {
    width: 16px; /* Réduit de 24px */
    height: 16px; /* Réduit de 24px */
  }
  
  .payment-details {
    flex: 1;
  }
  
  .payment-label {
    font-size: 0.875rem; /* Réduit de 1.125rem */
    font-weight: 500;
    color: #2d3748;
    margin-bottom: 0.25rem; /* Réduit de 0.5rem */
  }
  
  .payment-amount {
    font-size: 1.125rem; /* Réduit de 1.5rem */
    font-weight: 700;
    margin-bottom: 0.125rem; /* Réduit de 0.25rem */
  }
  
  .payment-subtitle {
    font-size: 0.75rem; /* Réduit de 0.875rem */
    color: #718096;
  }
  
  .check-icon {
    position: absolute;
    top: 0.5rem; /* Réduit de 1rem */
    right: 0.5rem; /* Réduit de 1rem */
    width: 16px; /* Réduit de 24px */
    height: 16px; /* Réduit de 24px */
  }
  
  /* Les variables de couleur restent les mêmes */
  .payment-full {
    --accent-color: #3182ce;
    --accent-light: #ebf8ff;
  }
  
  .payment-deposit {
    --accent-color: #805ad5;
    --accent-light: #f3ebff;
  }
  
  .payment-onsite {
    --accent-color: #38a169;
    --accent-light: #f0fff4;
  }

  .payment-card:disabled {
    cursor: not-allowed; /* Change le curseur pour indiquer que le bouton est inactif */
    opacity: 0.6; /* Réduit l'opacité pour signaler qu'il est désactivé */
    background-color: #f5f5f5; /* Change la couleur de fond pour la rendre grisée */
    border: 1px solid #ddd; /* Ajoute une bordure grise pour renforcer l'effet visuel */
  }
  
  .payment-card:disabled .icon-wrapper {
    color: #bbb; /* Grise l'icône à l'intérieur du bouton */
  }
  
  .payment-card:disabled .payment-details {
    color: #aaa; /* Grise le texte des détails de paiement */
  }
  
  
  /* Media Queries ajustées */
  @media (max-width: 640px) {
    .payment-card {
      min-width: 160px; /* Réduit de 280px */
    }
  
    .payment-label {
      font-size: 0.8125rem; /* Réduit de 1rem */
    }
  
    .payment-amount {
      font-size: 1rem; /* Réduit de 1.25rem */
    }
  
    .icon-wrapper {
      width: 28px; /* Réduit de 40px */
      height: 28px; /* Réduit de 40px */
    }
  
    .icon-wrapper svg {
      width: 14px; /* Réduit de 20px */
      height: 14px; /* Réduit de 20px */
    }
  }
  
  /* Scrollbar ajustée */
  .payment-options-wrapper::-webkit-scrollbar {
    height: 6px; /* Réduit de 8px */
  }
  
  /* Le reste des styles de scrollbar reste identique */