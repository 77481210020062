.contact {
  @apply relative min-h-screen flex items-center px-20;
}

.contact::before {
  content: "";
  background: url("../../assets/images/bg-contact.png") no-repeat;
  background-size: cover;
  background-position: center;
  @apply fixed top-0 left-0 w-full h-full opacity-90 -z-10;
}

.contact__info {
  background: url("../../assets/images/contact_info.png") no-repeat;
  background-size: contain;
  background-position: left center;
  @apply w-2/5 h-[600px];
}

body:has(.contact.mounted) .navbar {
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.15);
}

/* Media Queries pour le responsive */
@media screen and (max-width: 1024px) {
  .contact {
    @apply px-8;
  }

  .contact__info {
    @apply w-3/5 h-[400px];
  }
}

@media screen and (max-width: 768px) {
  .contact {
    @apply px-4;
  }

  .contact__info {
    @apply w-full h-[300px];
  }
}

@media screen and (max-width: 480px) {
  .contact__info {
    height: 250px;
    background-size: 100% auto;
  }
}