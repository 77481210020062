.signup-dropdown-container {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.signup-button {
  /* padding: 0.75rem 1.5rem;
  font-weight: 500;
  color: #bd193b;
  background: transparent;
  border: 2px solid #bd193b;
  border-radius: 12px; */
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 48px;
  margin-right: 0px;
}

.signup-button:hover {
  background: rgba(189, 25, 59, 0.04);
  transform: translateY(-1px);
}

.signup-dropdown-menu {
  position: absolute;
  top: calc(100% + 12px); /* Positionnement juste sous le bouton */
  right: 0; /* Alignement à droite */
  background: linear-gradient(135deg, #ffffff, #f7f7f7); /* Dégradé subtil */
  border-radius: 16px; /* Coins arrondis pour un look doux */
  box-shadow: 
    0 10px 20px rgba(0, 0, 0, 0.15),
    0 4px 6px rgba(0, 0, 0, 0.1); /* Ombre double pour une profondeur réaliste */
  overflow: hidden; /* Éviter les débordements */
  padding: 1.5rem;
  width: 340px; /* Largeur constante pour un design épuré */
  z-index: 1000; /* Toujours au-dessus */
  opacity: 0; /* Initialement invisible */
  transform: translateY(-20px) scale(0.95); /* Position initiale pour l'animation */
  animation: dropdownSlideIn 0.4s cubic-bezier(0.16, 1, 0.3, 1) forwards; /* Animation */
}

/* Animation d'apparition */
@keyframes dropdownSlideIn {
  from {
    opacity: 0;
    transform: translateY(-20px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 12px;
  transition: all 0.3s ease;
  background: transparent;
  color: #333;
  text-decoration: none;
  gap: 12px;
}

.dropdown-item:hover {
  background: rgba(189, 25, 59, 0.1); /* Rouge doux au survol */
  transform: translateX(8px); /* Déplacement subtil au survol */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08); /* Accentuation de l'ombre */
}

.dropdown-item-title {
  font-weight: bold;
  font-size: 1rem;
  color: #111;
  margin-bottom: 4px;
}

.dropdown-item-description {
  font-size: 0.85rem;
  color: #555;
}

.signup-dropdown-menu:hover {
  box-shadow: 
    0 12px 24px rgba(0, 0, 0, 0.2),
    0 6px 8px rgba(0, 0, 0, 0.1); /* Ombres amplifiées au survol */
}


.dropdown-menu-header {
  color: #111;
  font-size: 1.25rem;
  font-weight: 700;
  padding: 0.75rem 1rem;
  margin-bottom: 0.75rem;
  text-align: center;
  letter-spacing: -0.02em;
  background: linear-gradient(135deg, #1a1a1a 0%, #333 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

/* Version mobile */
@media (max-width: 768px) {
  .signup-button {
    margin: 12px auto;
  }
  
  .signup-dropdown-menu {
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 16px 16px 0 0; /* Coins arrondis uniquement en haut */
    width: 100%;
    max-width: none;
    padding: 2rem;
    animation: slideUp 0.4s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }

  .dropdown-item {
    margin-bottom: 1rem;
  }

  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
}