.anchor {
    @apply relative no-underline text-gray-800 font-semibold text-lg duration-200;
}
.anchor::before {
    content: "";
    transform-origin: center;
    @apply w-full h-1 absolute -bottom-2 bg-[#bd193b] duration-200 scale-x-0 rounded;
}
.anchor.active,
.anchor:hover {
    @apply text-[#bd193b];
}
.anchor.active::before,
.anchor:hover::before {
    @apply scale-x-100;
}
/* section.header {
    display: grid;
    grid-template-columns: repeat(30, 1fr);
}
section.header span {
    @apply border-r;
} */