.contact__form {
    position: absolute;
    right: 40px;
    width: 40%;
    height: 75%;
    display: flex;
    align-items: center;
  }
  
  .form-container {
    width: 100%;
    height: 100%;
    background-color: rgba(68, 68, 68, 0.75);
    border-radius: 24px;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .form-title {
    color: white;
    font-size: 24px;
    margin: 0 0 5px 0;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 5px;
  }
  
  .form-group:last-of-type {
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 14px;
    border: none;
    border-radius: 12px;
    background-color: #fff;
    font-size: 16px;
  }
  
  .form-group input::placeholder,
  .form-group textarea::placeholder {
    color: #888;
  }
  
  .form-group textarea {
    resize: none;
    min-height: 120px;
  }
  
  .submit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    padding: 14px;
    background-color: #C71B3B;
    color: white;
    border: none;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin: 0;
  }
  
  .submit-btn:hover {
    background-color: #b01734;
  }
  
  /* Media Queries */
  @media screen and (max-width: 1024px) {
    .contact {
      flex-direction: column;
      align-items: stretch;
      padding: 20px;
    }
  
    .contact__info {
      width: 100%;
      height: 400px;
    }
  
    .contact__form {
      position: static;
      width: 100%;
      height: auto;
      margin-top: 20px;
    }
    
    .form-container {
      min-height: 500px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .contact {
      padding: 15px;
    }
  
    .contact__info {
      height: 300px;
    }
    
    .form-container {
      min-height: 450px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .contact {
      padding: 10px;
    }
  
    .contact__info {
      height: 250px;
    }
    
    .form-container {
      min-height: 400px;
    }
  }