.beautician-popup {
  min-width: 240px;
  max-width: 280px;
  padding: 12px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.2s ease;
}

.beautician-popup:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(189, 25, 59, 0.15);
}

.popup-header {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
}

.popup-photo {
  width: 56px;
  height: 56px;
  border-radius: 12px;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.popup-title {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.popup-title h3 {
  margin: 0 0 6px 0;
  font-size: 15px;
  font-weight: 600;
  color: #2d3748;
  line-height: 1.2;
}

.popup-subtitle {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
}

.popup-subtitle .price {
  color: #bd193b;
  font-weight: 600;
  padding: 2px 6px;
  background: rgba(189, 25, 59, 0.08);
  border-radius: 4px;
}

.popup-subtitle .rating {
  color: #f4b400;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 2px;
}

.popup-content {
  font-size: 13px;
  border-top: 1px solid #f0f0f0;
  padding-top: 8px;
}

.popup-content .address {
  margin: 0 0 6px 0;
  color: #4a5568;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
}

.popup-content .services {
  margin: 0;
  color: #718096;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.service-tag {
  font-size: 11px;
  padding: 2px 8px;
  background: #f7fafc;
  border-radius: 12px;
  color: #4a5568;
  font-weight: 500;
}

/* Style de la popup Leaflet */
.leaflet-popup-content-wrapper {
  padding: 0;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.leaflet-popup-content {
  margin: 0;
}

.leaflet-popup-tip {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.popup-hover-area {
  position: relative;
}

.leaflet-popup.hover-active {
  opacity: 1;
  visibility: visible;
}

.beautician-popup {
  min-width: 240px;
  max-width: 280px;
  padding: 12px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.2s ease;
}

.popup-content {
  padding-top: 8px;
}

.services {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}