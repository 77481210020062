/* BeauticianPortalSidebar.css */
.sidebar-container {
    width: 280px;
    background-color: white;
    border-right: 1px solid var(--gray-200);
    height: calc(100vh - var(--header-height));
    position: fixed;
    top: var(--header-height);
    left: 0;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
    z-index: 50;
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.05);
  }
  
  .sidebar-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1.5rem 0;
  }
  
  .sidebar-branding {
    padding: 0 1.5rem 1.5rem;
    border-bottom: 1px solid var(--gray-200);
    margin-bottom: 1rem;
  }
  
  .sidebar-brand-text {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--primary);
  letter-spacing: -0.025em;
  margin-left: 3rem; /* Ajouter cette ligne */
}
  
  .sidebar-nav {
    padding: 0 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .sidebar.compact .sidebar-nav {
    padding: 0 0.25rem; /* Réduire le padding en mode compact */
  }
  
  .sidebar-link {
    display: flex;
    align-items: center;
    padding: 0.75rem;
    color: var(--gray-700);
    text-decoration: none;
    border-radius: 0.5rem;
    transition: all 0.2s ease;
    position: relative;
    overflow: hidden;
  }
  
  .sidebar-link:hover {
    background-color: var(--gray-50);
    color: var(--primary);
  }
  
  .sidebar-link-active {
    background-color: var(--primary-light);
    color: var(--primary);
    font-weight: 500;
  }
  
  .sidebar-link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 0.75rem;
    flex-shrink: 0;
  }
  
  .sidebar-link-text {
    font-size: 0.9375rem;
  }
  
  /* Overlay pour mobile */
  .sidebar-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 40;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    backdrop-filter: blur(4px);
  }
  
  .sidebar-overlay.visible {
    opacity: 1;
  }
  
  .sidebar-link-icon {
    position: relative;
  }
  
  .notification-dot {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 8px;
    height: 8px;
    background-color: #3b82f6;
    border-radius: 50%;
    border: 2px solid white;
  }
  
  /* Animation pour le point de notification */
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }
  
  .notification-dot {
    animation: pulse 2s infinite;
  }

  /* Ajoutez ces styles dans BeauticianPortalSidebar.css */
.sidebar-link-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-message-count {
  position: absolute;
  top: -8px;
  right: -8px;
  min-width: 18px;
  height: 18px;
  background-color: #3b82f6;
  color: white;
  border-radius: 10px;
  font-size: 11px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Adaptation pour mobile */
@media (max-width: 768px) {
  .sidebar-message-count {
    top: -6px;
    right: -6px;
    min-width: 16px;
    height: 16px;
    font-size: 10px;
  }
}

  @media (max-width: 768px) {
    .sidebar-container {
      transform: translateX(-100%);
      width: 100%;
      max-width: 300px;
    }
  
    .sidebar-container.open {
      transform: translateX(0);
    }
  
    .sidebar-overlay {
      display: block;
    }
  
    .sidebar-overlay:not(.visible) {
      pointer-events: none;
    }
  }

  /* Ajouter à BeauticianPortalSidebar.css */
.sidebar.compact .sidebar-link {
  padding: 0.75rem;
  justify-content: center;
}

.sidebar.compact .sidebar-link-text {
  display: none;
}

.sidebar.compact .sidebar-branding {
  text-align: center;
  padding: 0.75rem;
}

.sidebar.compact .sidebar-brand-text {
  display: none;
}

.sidebar.compact .sidebar-link-icon {
  margin-right: 0;
}

.compact-toggle {
  position: fixed;
  top: calc(var(--header-height) + 0.75rem);
  left: 1rem;
  z-index: 50;
  padding: 0.5rem;
  background: white;
  border: 1px solid var(--gray-200);
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}


.compact-toggle:hover {
  background-color: var(--gray-50);
}

@media (max-width: 768px) {
  .compact-toggle {
    display: none;
  }
}