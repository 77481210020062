.home {
  padding-top: 0;
}

/* Section des esthéticiennes */
.beauticians-section {
  position: relative;
  z-index: 1; /* Réduire le z-index */
  background: white;
  margin-top: -40px;
  border-radius: 70px 70px 70px 70px;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

/* Ajoutez ces styles à votre fichier Home.css */
.section-header {
  display: flex;
  flex-direction: column;  /* Met les éléments en colonne */
  align-items: center;
  gap: 1rem;  /* Espace entre les éléments */
  margin-bottom: 3rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.section-title {
  font-size: 2.25rem;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;  /* Reset des marges */
  position: relative;
  padding-bottom: 1.25rem;
  text-align: center;
}

.section-subtitle {
  font-size: 1.125rem;
  color: #666;
  margin: 0;  /* Reset des marges */
  line-height: 1.6;
  text-align: center;
}
.section-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background-color: var(--primary);
  border-radius: 2px;
}

.section-subtitle {
  font-size: 1.125rem;
  color: #666;
  margin: 0;
  line-height: 1.6;
}

/* Features Section */
.features {
  position: relative;
  z-index: 3;
  background-color: #f8f9fa;
  padding: 5rem 0;
}

.features h2 {
  color: #333;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 3rem;
  position: relative;
}

.features h2::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background-color: #bd193b;
  border-radius: 2px;
}

/* Card Features */
.icone-card {
  background: white;
  border-radius: 20px;
  padding: 2.5rem 2rem;
  height: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.icone-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, #bd193b, #e85474);
  transform: scaleX(0);
  transition: transform 0.3s ease;
  transform-origin: left;
}

.icone-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(189, 25, 59, 0.1);
}

.icone-card:hover::before {
  transform: scaleX(1);
}

/* Icônes */
.icone-card i {
  font-size: 2.5rem;
  color: #bd193b;
  margin-bottom: 1.5rem;
  display: inline-block;
  padding: 1rem;
  border-radius: 50%;
  background: rgba(189, 25, 59, 0.1);
  transition: all 0.3s ease;
}

.icone-card:hover i {
  transform: scale(1.1) rotate(5deg);
  background: rgba(189, 25, 59, 0.15);
}

/* Texte */
.icone-card h3 {
  color: #333;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.icone-card p {
  color: #666;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 0;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .beauticians-section {
    margin-top: -35px;
    border-radius: 60px 60px 60px 60px;
    padding-top: 2.5rem;
  }

  .features h2 {
    font-size: 2.2rem;
  }
}

@media (max-width: 991.98px) {
  .beauticians-section {
    margin-top: -30px;
    border-radius: 50px 50px 50px 50px;
    padding-top: 2rem;
  }

  .features {
    padding: 4rem 0;
  }

  .icone-card {
    padding: 2rem 1.5rem;
    margin-bottom: 2rem;
  }

  .features h2 {
    font-size: 2rem;
  }
}

@media (max-width: 767.98px) {
  .beauticians-section {
    margin-top: -25px;
    border-radius: 40px 40px 40px 40px;
    padding-top: 1.5rem;
  }

  .section-title {
    font-size: 1.75rem;
    padding-bottom: 1rem;
  }

  .section-subtitle {
    font-size: 1rem;
  }

  .features h2 {
    font-size: 1.8rem;
  }

  .icone-card {
    margin-bottom: 1.5rem;
  }

  .icone-card h3 {
    font-size: 1.3rem;
  }
}

@media (max-width: 575.98px) {
  .beauticians-section {
    margin-top: -20px;
    border-radius: 30px 30px 30px 30px;
    padding-top: 1rem;
  }

  .features {
    padding: 3rem 0;
  }

  .features h2 {
    font-size: 1.75rem;
  }

  .icone-card {
    padding: 1.5rem;
  }

  .icone-card i {
    font-size: 2rem;
    padding: 0.8rem;
  }
}

/* Ajoutez ces styles à Home.css */

/* Reviews Section */
.reviews-section {
  position: relative;
  z-index: 2;
  background: #fff;
  padding: 4rem 0;
  padding-top: 0;
}

/* Services Section */
.services-wrapper {
  position: relative;
  z-index: 2;
  background: white;
  padding: 4rem 0;
}

/* Media Queries */
@media (max-width: 991.98px) {
  .reviews-section,
  .services-wrapper {
    padding: 3rem 0;
  }
}

@media (max-width: 767.98px) {
  .reviews-section,
  .services-wrapper {
    padding: 2rem 0;
  }
}