/* BeauticianSettings.css */
.drop-target {
  padding: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.drop-target-active {
  background-color: #f0f0f0;
  transform: scale(1.01);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.settings-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 2rem;
  }
  
  /* Navigation Styles */
  .settings-navigation {
    background: white;
    border-radius: 12px;
    padding: 1rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
  }
  
  .settings-nav-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
    border: none;
    background: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
    text-align: left;
  }
  
  .settings-nav-item:hover {
    background: var(--gray-50);
  }
  
  .settings-nav-item.active {
    background: var(--primary-light);
    color: var(--primary);
  }
  
  .nav-item-icon {
    padding: 0.5rem;
    background: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .active .nav-item-icon {
    background: var(--primary-light);
    color: var(--primary);
  }
  
  .nav-item-content {
    flex: 1;
  }
  
  .nav-item-content h3 {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .nav-item-content p {
    margin: 0;
    font-size: 0.75rem;
    color: var(--gray-600);
  }
  
  .nav-item-arrow {
    color: var(--gray-400);
  }
  
  /* Content Styles */
  .settings-content {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .settings-header {
    padding: 1.5rem;
    border-bottom: 1px solid var(--gray-200);
  }
  
  .settings-header h2 {
    margin: 0;
    font-size: 1.25rem;
    color: var(--gray-900);
  }
  
  /* Form Styles */
  .settings-form {
    padding: 1.5rem;
  }
  
  .form-section {
    margin-bottom: 2rem;
  }
  
  .form-section h3 {
    margin: 0 0 1rem 0;
    font-size: 1rem;
    color: var(--gray-900);
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    color: var(--gray-700);
  }
  
  .input-with-icon {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border: 1px solid var(--gray-200);
    border-radius: 8px;
    transition: all 0.2s;
  }
  
  .input-with-icon:focus-within {
    border-color: var(--primary);
    box-shadow: 0 0 0 2px var(--primary-light);
  }
  
  .input-with-icon svg {
    color: var(--gray-500);
  }
  
  .input-with-icon input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 0.875rem;
  }
  
  .form-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  
  /* Notifications Styles */
  .notifications-settings {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .notification-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: var(--gray-50);
    border-radius: 8px;
  }
  
  .notification-info h4 {
    margin: 0 0 0.25rem 0;
    font-size: 0.875rem;
    color: var(--gray-900);
  }
  
  .notification-info p {
    margin: 0;
    font-size: 0.75rem;
    color: var(--gray-600);
  }
  
  /* Switch Toggle */
  .switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--gray-300);
    transition: .4s;
    border-radius: 24px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: var(--primary);
  }
  
  input:checked + .slider:before {
    transform: translateX(24px);
  }
  
  /* Action Buttons */
  .settings-action-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    background: var(--gray-50);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .settings-action-button:hover {
    background: var(--gray-100);
  }
  
  .action-info {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .action-info h4 {
    margin: 0 0 0.25rem 0;
    font-size: 0.875rem;
    color: var(--gray-900);
  }
  
  .action-info p {
    margin: 0;
    font-size: 0.75rem;
    color: var(--gray-600);
  }

  .show-settings-menu-button {
    display: none;
  }

  .hide-settings-menu-button {
    display: none;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {

    .settings-form {
      padding: 0.5rem;
    }

    .settings-container {
      /* grid-template-columns: 1fr; */
      padding: 1rem;
      display: block;
    }
  
    .form-row {
      grid-template-columns: 1fr;
    }
  
    .settings-navigation-show {
      margin-bottom: 1rem;
      display: block;
      position: absolute;
      top: 10vh;
      left: 0;
      z-index: 35;
    }

    .settings-navigation-hidden {
      margin-bottom: 1rem;
      display: block;
      position: absolute;
      top: 10vh;
      left: 0;
      transform: translateX(-96vw);
      z-index: 35;
    }

    .show-settings-menu-button {
      display: block;
      position: absolute;
      top: 50vh;
      left: 0;
      z-index: 36;
    }

    .hide-settings-menu-button {
      display: flex;
    }
  }