.layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 70px;
  }
  
  .main-content {
    flex: 1;
    position: relative;
    z-index: 2;
    background: transparent;
  }
  
  .footer {
    position: relative;
    z-index: 3;
    background: white;
    margin-top: auto;
  }