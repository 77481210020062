/* Styles pour la carte */
.custom-marker-icon {
  background: none;
  border: none;
}

.custom-marker-icon svg {
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  transition: transform 0.2s ease;
}

.custom-marker-icon:hover svg {
  transform: scale(1.1);
}

/* Styles pour la popup */
.leaflet-popup-content-wrapper {
  padding: 0;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 280px;
}

.leaflet-popup-content {
  margin: 0;
  width: 280px !important;
}

.leaflet-popup-tip-container {
  display: none; /* Cache la flèche de la popup */
}

.map-popup {
  background: white;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.map-popup:hover {
  transform: translateY(-2px);
}

.map-popup-photo {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.map-popup-content {
  padding: 1rem;
}

.map-popup h3 {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--gray-900);
  margin: 0 0 0.5rem 0;
}

.map-popup-location {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--gray-700);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.map-popup .specialties {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  margin-top: 0.5rem;
}

.map-popup .specialty-tag {
  background: var(--primary-light);
  color: var(--primary);
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}

.view-profile-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.75rem;
  margin-top: 1rem;
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.view-profile-btn:hover {
  background-color: var(--primary-dark);
  transform: translateY(-1px);
}

.view-profile-btn:active {
  transform: translateY(0);
}

/* Personnalisation des contrôles de la carte */
.leaflet-control-zoom {
  border: none !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1) !important;
}

.leaflet-control-zoom a {
  color: var(--primary) !important;
  border-color: var(--gray-200) !important;
}

.leaflet-control-zoom a:hover {
  background-color: var(--primary-light) !important;
  color: var(--primary-dark) !important;
}