.beautician-card {
  position: relative;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.35);
  transition: all 0.3s ease;
  cursor: pointer;
  outline: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  aspect-ratio: 2/3;
  border: .5px solid #cccccc;
}

.card-content {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 33%;
  background: white;
}

.card-status {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 5px;
  font-size: 12px;
}

.card-status.news {
  padding: 5px 10px;
  border-radius: 10px 0px 0 10px;
  color: white;
  background: #bd193b;
}
.card-status.trending {
  padding: 5px 10px;
  border-radius: 10px 0px 0 10px;
  color: white;
  background: #FF9900 ;
}

.beautician-card:hover {
  transform: translateY(-5px);
  border: .5px solid rgb(204, 204, 204, .5);
  box-shadow: 0 3px 20px rgba(189, 25, 59, 0.5);
}

.card-image-container {
  width: 100%;
  height: 67%;
  overflow: hidden;
  position: relative;
  background: #f8f9fa;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card-content {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 33%;
  background: white;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.name-location {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.card-name {
  font-size: 0.9rem;
  font-weight: 600;
  color: #333;
  margin: 0;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-city {
  color: #666;
  font-size: 0.8rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: .25em;
}

.card-rating-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-rating {
  display: flex;
  align-items: center;
  gap: 0.15rem;
}

.card-reviews {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.75rem;
  color: #666;
}

.reviews-count {
  color: #333;
  font-weight: 500;
}

/* Styles pour les actions */
.card-actions {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  z-index: 1;
}

.share-button {
  background: white;
  border: 1px solid #bd193b;
  color: #bd193b;
  padding: 0.4rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.share-button:hover {
  background: #bd193b15;
}

.share-button:active {
  transform: scale(0.95);
}

.share-popup {
  position: absolute;
  bottom: 100%;
  left: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  padding: 0.75rem;
  z-index: 1000;
  min-width: 200px;
  margin-bottom: 8px;
}

.close-popup {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.share-options button {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
  width: 100%;
  text-align: left;
}

.share-options button:hover {
  background: #f5f5f5;
}

.share-options button span {
  font-size: 0.9rem;
  color: #333;
}

.whatsapp-share {
  color: #25D366;
}

.copy-link {
  color: #666;
}

.copied-message {
  position: absolute;
  bottom: -2rem;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: white;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: nowrap;
  animation: fadeInOut 2s ease;
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: translate(-50%, 10px); }
  15% { opacity: 1; transform: translate(-50%, 0); }
  85% { opacity: 1; transform: translate(-50%, 0); }
  100% { opacity: 0; transform: translate(-50%, -10px); }
}

/* Style pour le bouton de réservation */
.book-button {
  background: #bd193b;
  color: white;
  border: none;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.book-button:hover {
  background: #a01532;
}

@media (max-width: 768px) {
  .card-content {
    padding: 0.35rem; /* Réduit encore plus */
    gap: 0.15rem; /* Réduit encore plus l'espacement vertical */
  }

  .card-name {
    font-size: 0.7rem; /* Encore plus petit */
  }

  .card-city {
    font-size: 0.65rem;
  }

  .card-rating {
    transform: scale(0.85); /* Réduit davantage les étoiles */
    transform-origin: left;
  }

  .card-reviews {
    font-size: 0.6rem;
  }

  .card-rating-row {
    margin: 0; /* Supprime la marge */
  }

  .name-location {
    margin-bottom: 0; /* Supprime la marge */
  }

  .book-button {
    padding: 0.25rem 0.5rem;
    font-size: 0.65rem;
    white-space: nowrap;
  }

  .share-button {
    padding: 0.25rem;
    min-width: 24px;
    min-height: 24px;
  }

  .share-button svg {
    width: 12px;
    height: 12px;
  }

  /* Réorganisation des espaces */
  .card-actions {
    gap: 0.25rem;
    margin-top: 0.15rem; /* Réduit la marge du haut */
  }
  
  .card-header {
    margin: 0; /* Supprime toutes les marges */
  }
}

@media (max-width: 360px) {
  .card-content {
    padding: 0.3rem;
    gap: 0.1rem; /* Minimum d'espacement */
  }

  .card-name {
    font-size: 0.65rem;
  }

  .card-city {
    font-size: 0.6rem;
  }

  .card-reviews {
    font-size: 0.55rem;
  }

  .book-button {
    padding: 0.2rem 0.4rem;
    font-size: 0.6rem;
  }

  .share-button {
    padding: 0.2rem;
    min-width: 22px;
    min-height: 22px;
  }

  .share-button svg {
    width: 11px;
    height: 11px;
  }
}