.header,
.user-data {
  display: grid;
  grid-template-columns: repeat(40, 1fr);
}
.header span,
.user-data span:not(.end) {
  @apply border-r;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .start {
    border-right: none;
}
.end {
    border-right: none;
} */
