/* BeauticianPortalLayout.css */
:root {
    --header-height: 64px;
    --sidebar-width: 280px;
  }
  
  .portal-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .portal-content {
    display: flex;
    flex: 1;
    margin-top: var(--header-height);
  }
  
  /* Sidebar Styles */
  .sidebar {
    width: var(--sidebar-width);
    background: white;
    border-right: 1px solid var(--gray-200);
    height: calc(100vh - var(--header-height));
    position: sticky;
    top: var(--header-height);
    flex-shrink: 0;
  }
  
  /* Main Content Styles */
  .main-container {
    flex: 1;
    min-width: 0; /* Empêche le dépassement du contenu */
    padding: 2rem;
    background-color: var(--gray-50);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .sidebar {
      position: fixed;
      left: 0;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
      z-index: 40;
    }
  
    .sidebar.sidebar-open {
      transform: translateX(0);
    }
  
    .main-container {
      padding: 1rem;
    }
  }

  
  .sidebar.compact {
    width: 64px; /* Nouvelle largeur en mode compact */
    flex-shrink: 0; /* Assure que le sidebar ne rétrécit pas */
  }


/* Modifier la media query existante */
@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    left: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 40;
  }

  .sidebar.sidebar-open {
    transform: translateX(0);
  }

  .sidebar.compact + .main-container {
    margin-left: 0;
  }

  .main-container {
    padding: 0rem;
  }
}