:root {
  --primary: #bd193b;
  --primary-light: rgba(189, 25, 59, 0.1);
  --animation-duration: 30s;
  --card-width: calc(100% / 5);
}

.services-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  overflow: hidden;
}

.services-carousel {
  position: relative;
  overflow: hidden;
  margin: 0 -1rem;
  padding: 1rem 0;
}

.services-track {
  display: flex;
  gap: 1rem;
  animation: scroll var(--animation-duration) linear infinite;
  width: fit-content;
}

.services-track.paused {
  animation-play-state: paused;
}

.service-card {
  flex: 0 0 250px;
  background: white;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 0 4px 25px rgba(189, 25, 59, 0.1);
  transition: transform 0.3s ease;
}

.service-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}

.service-card-content-concept {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.2rem;
}

.service-image-wrapper {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
}

.service-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

/* Styles pour les cercles en contour */
.service-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px; /* 60px + 2 * (6px padding) */
  height: 72px;
  border-radius: 50%;
}

.service-image-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 4px;
}

.service-text {
  text-align: center;
}

.service-title {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

/* Garder le reste du CSS inchangé */

.service-description {
  font-size: 0.875rem;
  color: #666;
  line-height: 1.4;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% / 3));
  }
}

@media (max-width: 1400px) {
  .service-card {
    flex: 0 0 300px;
  }
}

@media (max-width: 1200px) {
  .service-card {
    flex: 0 0 350px;
  }
}

@media (max-width: 992px) {
  .service-card {
    flex: 0 0 400px;
  }
}

@media (max-width: 768px) {
  .service-card {
    flex: 0 0 300px;
  }
  
  .services-container {
    padding: 1rem;
  }

  /* Maintenir la taille des images en responsive */
  .service-image-wrapper {
    min-width: 60px;
  }

  .service-image {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
  }

  /* Maintenir la taille du conteneur du gradient */
  .bg-gradient-to-r {
    min-width: calc(60px + 0.5rem);
    min-height: calc(60px + 0.5rem);
  }
}

@media (max-width: 480px) {
  .service-card {
    flex: 0 0 250px;
  }
}